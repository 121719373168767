<template>

   
   
     <div class="row bordertop pt10 pb10">

          <div class="col col-6"> 
               <div v-if="numberoflikes >0" class="klein tekstblauw tooltip" ><span class="bi bi-hand-thumbs-up-fill klein mr5"></span>{{numberoflikes}}<span class="popup"><div>{{likes}}</div></span></div>
          </div>

          <div class="col col-6">
                    <a v-if="item.numberofcomments==1" v-on:click="showcomments()" class="klein tekstblauw floatright">{{item.numberofcomments}} {{translate.COMMENTS_OPMERKING}}</a>
                    <a v-if="item.numberofcomments>1" v-on:click="showcomments()" class="klein tekstblauw floatright">{{item.numberofcomments}} {{translate.COMMENTS_OPMERKINGEN}}</a>
         
               </div>  
     </div>

     <div class="row pt10 pb5 bordertop">

          <div class="col col-6">
                    <a v-on:click="likeitem()" class="klein tekstblauw" v-if="userlikespost"><span class="bi bi-hand-thumbs-up-fill floatleft klein mr5"></span>{{ translate.POSTSPOST_VINDIKLEUK }}</a>
                    <a v-on:click="likeitem()" class="klein tekstgrijs" v-if="!userlikespost"><span class="bi bi-hand-thumbs-up floatleft klein mr5"></span>{{ translate.POSTSPOST_VINDIKLEUK }}</a>
          </div>

          <div class="col col-6">
                    <a v-on:click="createcomment()" class="tekstgrijs klein floatright"><span class="bi bi-chat-left klein mr5"></span>{{translate.POSTSPOST_OPMERKINGMAKEN}}</a>
          </div>  
     </div> 
     
</template>


<script>
import axios from "axios";

export default{
    
     data(){
          return {    
                              
          }
     }, 
     props: ["item"] ,


     methods: {

     createcomment() {


          if (this.loggedin){

               this.setactiveproperty('item',this.item);
               this.setactiveproperty('panel','CreateComment');

          } else {

               this.setactiveproperty('panel','Login');
          }

       
     },

     showcomments(){

          this.setactiveproperty('item',this.item);
          this.setactiveproperty('panel','ShowComments');

     },

     likeitem() 
     {
          

          if (this.loggedin) 
          {
               
               
               if (this.$store.state.config.debug==1) 
               {
                    console.log('likeitem');
               }

               // console.log(this.item);

               var post = new Object();
               post.task = 'likeitem';  
              
               post.itemtype = this.item.type;
               post.itemid = this.item.id;
               post.hdaccesstoken = this.$store.state.user.hdaccesstoken;

               // console.log(post);


               axios.post( this.$store.state.config.apiserver + 'interactions/interactionfooter.php', post)
               .then(response => {                        
          
               if (this.$store.state.config.debug==1) 
               {         
                    this.setactiveproperty('task','refresh');
                    this.setactiveproperty('taskitemtype',this.item.type);
                    this.setactiveproperty('taskitemid',this.item.id);


                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }
                    
               }

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });


          } else {

               this.setactiveproperty('panel','Login');

          }

          
     },
     


     },
     computed:{          
   
       
          likes(){

               var html = '';


               if (this.item.likes) {

                    var likes = JSON.parse(this.item.likes);    

                    likes.forEach(function (like) {

                         html +=  '' + like.firstname + ' ' + like.lastname + '\n';


                    });

               


               }


                              
               return html;

          },
          numberoflikes() {     
               
               if (this.item.likes) 
               {

               var number =  JSON.parse(this.item.likes).length;
               return number;
               } 

               return '';
          },
          userlikespost() {

               if (this.item.likes) 
               {

                    if(this.$store.state.user){
                         
                         const userid = this.$store.state.user.id;         
                    
                    
                         const likes = JSON.parse(this.item.likes);

                         const like = likes.find( function( like ){
                              return like.id === userid;
                         } );   

                         if (typeof like !== 'undefined') {
                              return true;
                         } else {
                              return false;
                         }    


                    } else {

                         return false;
                    }

                 

               } else {

                    return false;
               }

          },
         
          
     },



     
}
</script>

