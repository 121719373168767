<template>

     <div class="view">  
     
          <h1>{{ translate.WAIT_KOP }}</h1>
          
          {{ translate.WAIT_TEXT }}
          <br><br>
          {{ translate.WAIT_BEFOREDAYS }} {{ this.$store.state.games.game.daystillchat  }} {{ translate.WAIT_AFTERDAYS }}

          
     </div>
     
</template>


<script>
//import axios from "axios";

export default{
    
     data(){
          return {    
                              
          }
     },

     components: {
         
     },
     computed:{          
   
  

          
     },
     watch: {



     },


     methods: {




     




     }
}
</script>

<style scoped>

.view {

     text-align: center;
}

</style>

