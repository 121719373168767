<template>


<div id="CommentsComment" v-if="comment">  

  
     <div class="postheader">

          <div class="links">

               <img class="profilepicture" :src="profilepicture" :title="comment.id" />                          
               
          </div>     

          <div class="midden w100">

               <div class="heelklein tekstgrijs mb5">

                    <span class="dik tekstzwart"> {{ fullname }}</span> &bull; {{ time() }} &bull;
                    {{ comment.clubNameshort }} &bull; {{ comment.clubPlace }} &bull; {{ comment.language }}               
               </div>

               <div class="commenttext mb10">{{ comment.text }} </div>  

               

          </div>   

   

     </div> 

     
     

    

     <CommentsCommentAnswers v-if="comment.hasanswers" v-bind:commentid="commentid"/>

     <div class="row mb30 ml50">
   
          <div class="col col-12">
               <a v-on:click="createanswer()" class="klein floatright tekstgrijs"><i class="bi klein bi-reply mr5"></i>{{translate.COMMENTS_ANTWOORDEN}}</a>
          </div>

     </div>
  
        

   
</div>

<div v-else class="loadingpost box-shadow">

     <div class="header">
          <div class="picture"></div>
          <div class="regel"></div>
          <div class="regel"></div>               
     </div>


</div>     


</template>

<script>

import axios from "axios";
import CommentsCommentAnswers from '@/components/interactions/panels/ShowCommentsCommentAnswers.vue'


export default {

    name: 'Comment',

    data() {
        return{
           
           comment: null,
           datafetched: false
            
        }
    },

    components: {
        CommentsCommentAnswers
    },

    props: ["commentid"]      
    ,
    methods:{

          getcomment() {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getcomment');
               }

               var post = new Object();
               post.task = 'getcomment';
               post.commentid = this.commentid.id;
                    
               axios.post( this.$store.state.config.apiserver + 'interactions/panels/ShowCommentsComment.php', post)
               .then(response => {
                    this.comment = response.data;
                    console.log(response.data);
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });


          },

          createanswer() 
          {    
               if (this.loggedin){

                    this.setactiveproperty('toppanel','CreateAnswer');
                    this.setactiveproperty('commentid',this.commentid.id);

               } else {
                    this.setactiveproperty('panel','Login');
               }

          
           
          },
          time() 
          {

               var now = Date.now() /1000 |0             
               var created = this.comment.unixtime;
               var diff = now-created;

               var minutes = Math.floor(diff/60);         
               if (minutes<61) {
                    
                    return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;

               } 
               

               var hours = Math.floor(minutes/60);
               if (hours<25)
               {    
                    if (hours==1) {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
                    } else
                    {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
                    }
                    
               }

               var days = Math.floor(hours/24);
               if (days<31)
               {    
                    if (days==1) {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
                    } else
                    {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
                    }

               }

               var months = Math.floor(days/30);
               if (months<12)
               {
                    if (months==1) {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
                    } else
                    {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
                    }

               }
               
               var years = Math.floor(months/12);    

               if (years==1) {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
               } else
               {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
               }

          }

    },

    computed: {

          currentLanguageWebsite()
          {
          return this.$store.state.current.languageWebsite;
          },

          profilepicture () {            
          
               return 'https://www.hockeydo.com/users/'  + this.comment.userimagename + '.png';
          },
          
          
          fullname () {
               return this.comment.userFirstname + ' ' + this.comment.userLastname;
          },

          clubCountry() 
          {
               return this.comment['clubCountry' + this.currentLanguageWebsite];

          },
          showanswers() {
               
               return this.numberofanswers;
          },
   
     },
     watch: {
          commentid(){

               this.getcomment();
          },
       
    

     },


     mounted() 
     {
               this.getcomment();

               window.setInterval(() => {
               this.time()
               }, 60000)

     }


  
}
</script>

<style scoped>

.commenttext {
    background: #eee;   
    padding: 5px 20px;
    border-radius: 15px;
    display: inline-block;
   
}

</style>

