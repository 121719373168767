<template>

     <div class="view"> 

          <div class="row view_header">


               <div class="col col-12">

                    
                    <h2>{{translate.GROUPPROSPECTIVES_ASPIRANTLEDEN}}</h2>

                    <span v-if="group">

                         <span v-if="group.numberofmembers > 4">{{translate.PROSPECTIVES_ALLEENLEDEN}}</span> 
                         <span v-if="group.numberofmembers < 4">{{translate.PROSPECTIVES_OOKPROSPECTIVES}}</span> 

                    </span>

               </div>

          </div>     


            <div class="row mb15">

               <div class="col col-12">


                    <div v-if="prospectives">

                         <div v-for="prospective in prospectives" :key="prospective.id"> 
                              
                             
                              <Prospective v-bind:prospectiveid="prospective.userid"/>
                         </div>


                         <div v-if="prospectives.length==0">
                              {{translate.GROUPPROSPECTIVES_ERZIJNGEENAPSPIRANTLEDEN}}   

                         </div>  
                    </div>

                     


               </div>     
            

             

          </div>


          
     </div>
     
</template>


<script>
import axios from "axios";
import Prospective from '@/components/we/views/GroupProspectivesProspective.vue'

export default{
    
     data(){
          return {    
              
               prospectives: null,
               group: null
        
               
               
          }
     },
     components: {
          Prospective
     },




     methods: {

                    
               
          
          getgroupprospectives(){


               if (this.$store.state.config.debug==1) 
               {
                    console.log('getgroupprospectives');
               }
                    
               var post = new Object();
               post.task = 'getgroupprospectives';
               post.groupid = this.$store.state.we.group.id;                   
               post.hdaccesstoken = this.$cookie.get('hockeydo');

               // console.log(post);

               axios.post( this.$store.state.config.apiserver + 'we/views/GroupProspectives.php',post)
               .then(response => {          

          
                    this.prospectives = response.data;
                    // console.log(response.data);
               
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });





          },





     },

     computed:{          
   
 
          taskchanges(){

               return this.$store.state.active.task;
          }

          

          
     },
     watch: {

          taskchanges(){

               if (this.$store.state.active.task=='refreshprospectives') {

                    this.getgroupprospectives();

                    this.setactiveproperty('task','refreshvotes');
                    this.setactiveproperty('taskitemid',this.$store.state.we.vote.userid);
               
                    
               }


          }


     },

     mounted() {

     
          this.getgroupprospectives();

     }
}
</script>
