<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.POSTCREATE_KIESCATEGORIE}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                


                    <div v-for="postcat in postcats" :key="postcat.id">  

                  

                         <a class="button ghost mb10"  v-on:click="setpostcat(postcat)">{{postcat[this.$store.state.active.languagewebsite]}}</a>

                    </div>

          
               </div>


           



          </div>

     </transition>
  
</template>

<script>





export default {


     data() {
          return{
               panelname: 'PostCreateCats'
 
               
          }
     },


     methods:{

   
          setpostcat(postcat){

               this.setactiveproperty('postcat',postcat);
               this.setactiveproperty('toppanel','');

          }
          

     },

    computed: 
    {


          postcats() {

               return this.$store.state.static.postcats;
          }, 

    },



  
}
</script>

 
