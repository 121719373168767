<template> 

     <div v-if="deleted == false">

          <div class="view box-shadow" v-if="post">   

               <div class="postheader">

                    <div class="links"> 
                         <img class="profilepicture" :src="profilepicture"/> 
                    </div>     

                    <div class="midden">                      

                         <div class="heelklein tekstgrijs">                             
                              <i v-if="post.userstatus=='referee'" class="bi bi-shield-shaded mr5"></i><span class="dik tekstzwart"> {{ fullname }} </span> {{ post.id }} &bull; {{ time }} &bull;  
                              <span class="gotoclub" v-on:click="gotoclub()">  {{ post.clubNamelong }} &bull; {{ post.clubPlace }} &bull; {{ clubCountry }} </span> &bull; {{ postcat }} &bull; {{ location }}
                         </div> 
                    </div> 

                    <div class="rechts">
                         <i v-on:click="openitemmenu()" class="bi bi-three-dots tekstgrijs floatright"></i>
                    </div>

               
               </div>

               <strong>{{post.title}}</strong>



               <div v-if="view =='youtube'">

                    <div class="row" v-if="youtubesrc">  

                         <div class="col-6">
                              <iframe :width="this.$store.state.active.windowwidth*0.47" :height="youtubeheight/2" :src="youtubesrc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                                     
                         </div>

                         <div class="col-6">
                              {{ post.text }} 
                         </div>


                    </div>        


               </div>


               <div v-if="view !='youtube'">
                         
                    <div class="row" v-if="youtubesrc">    
                         <div class="youtubecontainer">
                              <iframe  :src="youtubesrc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                                                                     
                         </div>
                    </div> 
               </div>     



               <div class="row" v-if="instagramsrc">

                    <div class="instagramcontainer">

                         <blockquote class="instagram-media" data-instgrm-captioned :data-instgrm-permalink="instagramsrc" data-instgrm-version="14" style="max-width:1000px; min-width:326px; width:-webkit-calc(100%); width:100%;">
                         </blockquote>

                    </div>

               </div>     
               
     
               <div class="row fb" v-if="facebooksrc">      


                    <div class="facebookcontainer">
               
                         <iframe :src="facebooksrc" :width="this.$store.state.active.contentwidth" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>                                      

                    </div>     
               
               </div>     

               
               <div v-if="view !='youtube'">

                    <div class="row text">
                              <div class="col col-12 ">
                                   <div class="text mb5 pb15"> {{ post.text }}                        
                                   </div>
                              </div>    
                    </div>

               </div>  
          


               <div>
                    <Footer v-bind:item="post" />
               </div>


               
          </div>
          <div v-else class="loadingpost box-shadow">

               <div class="header">
                    <div class="picture"></div>
                    <div class="regel"></div>
                    <div class="regel"></div>
                    
               </div>

               <div class="regel"></div>
               <div class="regel"></div>
               <div class="regel"></div>    

          </div>   
     
     </div>

</template>

<script>

import axios from "axios";
import Footer from '@/components/interactions/interactionFooter.vue';



export default {

    name: 'Post',

    data() {
        return{           
          post: null,
          containerwidth: null,
          youtubeheight: null,
          deleted: false,
            
        }
    },

     components: {
          Footer
     },

    props: ["postid"]      
    ,
    methods:{

          gotoclub(){

               this.$router.push({ name: 'Posts', params:{ continent: this.post.continentSlug, country: this.post.countrySlug,region: this.post.regionSlug,club: this.post.clubSlug,scope: 'club',sub: 'posts', subsub: 'all' } })

          },   

          createcomment() {     
               
               if (this.loggedin) 
               {


                    this.setactiveproperty('panel','PostsCreateComment');
                    this.setactiveproperty('postid',this.post.id);

               } else {
                    this.setactiveproperty('panel','Login');
               }     
          },
          
          showcomments() {     
               
               this.setactiveproperty('panel','PostComments');
               this.setactiveproperty('postid',this.post.id);
          },
          openitemmenu(){   
               
               
               if (this.loggedin == false){

                    this.setactiveproperty('panel','Login');

               }

               if (this.loggedin == true){

                    this.setactiveproperty('item',this.post);
                    this.setactiveproperty('panel','ItemMenu');
               }

         
         
          },

          getpost() {

                    
               var postid = this.postid;

               if (postid)
               {
                    

                    if (this.$store.state.config.debug==1) 
                    {
                         console.log('getpost: ' + postid );
                    }
                         
                    var post = new Object();
                    post.task = 'getpost';
                    post.postid = postid;
                    post.hdaccesstoken = this.$cookie.get('hockeydo');
                    

                    axios.post( this.$store.state.config.apiserver + 'posts/views/PostsPost.php',post)
                    .then(response => {
                         
                         this.post = response.data; 
                         this.post.type = 'post';                              
                     
                         // console.log(response.data);                  
                         

                         this.loadinstagram;
                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });

               }
          },

          likepost() 
          {

               if (this.loggedin) 
               {
                    if (this.$store.state.config.debug==1) 
                    {
                         console.log('likepost');
                    }

                    

                    var post = new Object();
                    post.task = 'likepost';  
                    post.postid = this.postid;
                    post.hdaccesstoken = this.$cookie.get('hockeydo');
                    
                    axios.post( this.$store.state.config.apiserver + 'posts/views/PostsPost.php', post)
                    .then(response => {                        
               
                    if (this.$store.state.config.debug==1) 
                    {         
                         this.getpost() 

                         if (this.$store.state.config.debug==1) 
                         {
                         console.log(response.data);
                         }
                         
                    }

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });


               } else {

                    this.$store.dispatch('taskShowPanel','Login');

               }

               
          },


          calctime() 
          {
               var now = Date.now() /1000 |0             
               var created = this.post.unixtime;
               var diff = now-created;

               var minutes = Math.floor(diff/60);         
               if (minutes<61) {
                    
                    return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;

               } 
               

               var hours = Math.floor(minutes/60);
               if (hours<25)
               {    
                    if (hours==1) {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
                    } else
                    {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
                    }
                    
               }

               var days = Math.floor(hours/24);
               if (days<31)
               {    
                    if (days==1) {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
                    } else
                    {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
                    }

               }

               var months = Math.floor(days/30);
               if (months<12)
               {
                    if (months==1) {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
                    } else
                    {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
                    }

               }
               
               var years = Math.floor(months/12);    

               if (years==1) {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
               } else
               {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
               }
               
             



          },
          getyoutubeheight() {       

               var height =  document.querySelector('.view').offsetWidth * 0.56;                                      
               this.youtubeheight = height;
          },
         

     },

     computed: {


     

          location(){

               if (this.post.locationid>0) 
               {
                    var postcat = this.getobjectbyid(this.$store.state.static.locations,this.post.locationid);
                    return postcat[this.languagewebsite];
                             
               }     else {

                    return null

               }     
          },
          postcat(){

       
                   

               if (this.post.postcatid) 
               {
                    var postcat = this.getobjectbyid(this.$store.state.static.postcats,this.post.postcatid);
                    return postcat[this.languagewebsite];
                             
               }     else {

                    return null

               }       


               
          },     
          view() {               
               return this.$route.params.subsub;
          },    

  
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.post.userimagename + '.png';

          },

          fullname () {
               return this.post.userFirstname + ' ' + this.post.userLastname;
          },
   
          clubCountry() 
          {
               return this.post['clubCountry' + this.languagewebsite];

          },

          time()
          {

               return this.calctime();

          },
  
          numberofcomments()
          {              

               if (this.post.numbercomments==1)
               {
                    return this.post.numbercomments + ' ' + this.translate.COMMENTS_OPMERKING;
                    
               }

               if (this.post.numbercomments>1)
               {
                    return this.post.numbercomments + ' ' + this.translate.COMMENTS_OPMERKINGEN;
               }

               return ''
          },
          numberoflikes() {     
               
               if (this.post.likes) 
               {
               return JSON.parse(this.post.likes).length;
               } 

               return '';
          },
          userlikespost() {

               if (this.post.likes) 
               {

                    const userid = this.$store.state.user.id;               
                    const likes = JSON.parse(this.post.likes);

                    const like = likes.find( function( like ){
                         return like.id === userid;
                    } );   

                    if (typeof like !== 'undefined') {
                         return true;
                    } else {
                         return false;
                    }    

               } else {

                    return false;
               }
           
  


          },
          likes(){

               var html = '';

             
               var likes = JSON.parse(this.post.likes);                

               likes.forEach(function (like) {

                    html +=  '' + like.firstname + ' ' + like.lastname + '\n';

                    
               });

               html +=  '';                      
               return html;


               

          },

          youtubesrc() 
          {                      

               if (this.post.youtubecode) 
               {
                       
                    var src = '';

                    if (this.post.youtubecode.includes('iframe')){

                         var wrapper= document.createElement('div');
                         wrapper.innerHTML = this.post.youtubecode;
                         var iframe = wrapper.firstChild;

                         src = iframe.src;


                    } else {

                         src = 'https://www.youtube.com/embed/' + this.post.youtubecode.split('/')[3];

                    }

         
                    return src;

             
               
               }

               return null

          },
          facebooksrc() {
             

               if (this.post.facebookcode) {

                    var facebooksrc = this.post.facebookcode.split('"')[1];


                         if (facebooksrc) 
                         {
               
                                                       
                              return facebooksrc + '&width=' + this.$store.state.active.contentwidth;

                         }
          


               }
                
               return null
             
          },

          
 
          instagramsrc() {    


               if(this.post.instagramcode) 
               {

                    // Als de EMBEDCODE is gebruikt;
                    if (this.post.instagramcode.includes('blockquote')) {
                         
                         var wrapper= document.createElement('div');
                         wrapper.innerHTML = this.post.instagramcode;
                         var blockquote = wrapper.firstChild;
                         var src = blockquote.getAttribute('data-instgrm-permalink');

             
                         
                         return src;


                    }

                    // Als linkcode wordt gebruikt

                    if (this.post.instagramcode.includes('ig_web_copy_link')) {

                     
                         return this.post.instagramcode;



                    }     
                    

            


                    return '';
                    


               }
               return '';

          },

          loadinstagram() {

               if (window.instgrm) window.instgrm.Embeds.process();

               return '';

          },
          task(){


               return this.$store.state.active.task;

          }






     },

     watch: {

          task()
          {

               if (this.$store.state.active.task=="refresh") {

                    if (this.$store.state.active.taskitemtype == 'post') 
                    {

                         if (this.$store.state.active.taskitemid==this.post.id)
                         {
                            
                              this.getpost();

                              this.setactiveproperty('task',null);
                              this.setactiveproperty('taskitemtype',null);
                              this.setactiveproperty('taskitemid',null);

                         }

                    
                    }


               }

               if (this.$store.state.active.task=="delete") {

                    if (this.$store.state.active.taskitemtype == 'post') 
                    {

                         if (this.$store.state.active.taskitemid==this.post.id)
                         {
                         
                              this.deleted = true;

                         }


                    }


                    }



          }



     },
       
     mounted()
     {            
          this.getpost();
 
     }, 
     beforeCreate() {

          // INSTAGRAM
          var externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://www.instagram.com/embed.js')          
          document.head.appendChild(externalScript)


     }

  
}
</script>

<style scoped>

.youtubecontainer {
width: 100%;
height: 0;
padding-bottom: 50%;
overflow: hidden;
position: relative;
margin-top: 15px;
margin-bottom: 15px;
}

.youtubecontainer iframe {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
}

.facebookcontainer {
width: 100%;
margin-top: 15px;
}


.instagramcontainer{
    width: 100%; 
  
}

.gotoclub{
    cursor: pointer;
    transition: all 0.4s;
}

.gotoclub:hover{
    color: var(--groen);
}

.facebookcontainer{

margin-bottom: 15px;
}


</style>

