<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>  {{translate.USERPROFILE_IKSCHRIJF}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="language in this.$store.state.static.languages" :key="language">  

                         <a v-if="language.id != 1" v-on:click="setlanguage(language)" class="button ghost mb10">{{language.name}}</a>
                        

                    </div>

          
               </div>


           

          </div>

     </transition>
  
</template>

<script>


import axios from "axios";


export default {

 
     data() {
          return{
               
               panelname: 'UserProfileWriteLanguages'
          
               
          }
     },


     methods:{

          setlanguage(language)
          {

               if (this.$store.state.config.debug==1) 
               {
               console.log('setuserlanguagewrite');
               }    

               var post = new Object();
               post.task = 'setuserlanguagewrite';
               post.languageid =  language.id;
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
               .then(response => 
               {   
                 
                    this.$store.dispatch('fetchuser');  
                    this.setactiveproperty('toppanel','');
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }  

                    

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          }
          

     },



  
}
</script>

 
