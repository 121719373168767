<template>  
         
     <div id="width">
          <div class="fb-page-container">
               <div id="fb-root"></div>              

               <div class="fb-page" :data-href="facebookurl" data-show-posts="true" :data-width="datawidth" :data-height="dataheight" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"></div>

          </div>
     </div>


</template>

<script>
 


export default 
{
  
     data(){
     return{
          datawidth: 400,
          dataheight: 800,
            
     }
     },
     components: {
         
     },
     methods: {

          getwidth() {              
               this.datawidth = this.$store.state.active.contentwidth;
               this.dataheight = window.innerHeight - 190;
          },


            
          update () {      
               window.FB.XFBML.parse();
          }
   
     },
     computed: {

          facebookurl() {
               return this.$store.state.current.club.facebookurl;

          }
     

     },
     mounted() {


          setTimeout(this.getwidth, 200); 
          setTimeout(this.update, 500); 
     
     },
     beforeCreate() {

          // // Facebook SDK

          var externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v10.0&appId=188426574544846&autoLogAppEvents=1')
          externalScript.setAttribute('crossorigin', 'anonymous')       
          document.head.appendChild(externalScript)


     }

     

  

}
</script>  

<style scoped>


.fb-page {
  width: 100%;   
}


</style>

