<template>
     <div id="CommentsCommentAnswers"  v-if="answers"> 
          
          <div v-for="answer in answers" :key="answer.id">

          <div class="ml50 mb20">  

               <div class="postheader">

                    <div class="links">

                         <img :src="getprofilepicture(answer)" class="profilepicture" />                    

                  

                    </div> 

                    <div class="midden">

                         <div class="heelklein tekstgrijs mb5">
                              <span class="tekstzwart dik">{{answer.userFirstname }} {{answer.userLastname }}</span> &bull; {{ gettime(answer) }} &bull; 
                              {{answer.clubNameshort }} &bull;  {{answer.clubPlace }} &bull;  {{ getcountry(answer) }} &bull;  {{answer.language }} 
                              
                         </div>

                         <div class="answertext">{{answer.text}}</div>
                    </div>   

               </div>
  

               </div>    
          
          </div>
          
     </div>


</template>

<script>

import axios from "axios";

export default {

    name: 'CommentsCommentAnswers',

    data() {
        return{
          
           answers: null,
           datafetched: false
     
            
        }
    },

    props: ["commentid"]      
    ,

    methods:{

          gettime(answer)
          {
          
               var now = Date.now() /1000 |0             
               var created = answer.unixtime;
               var diff = now-created;
               var minutes = Math.floor(diff/60); 
                    
               if (minutes<61) {
                    
                    return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;

               } 
               

               var hours = Math.floor(minutes/60);
               if (hours<25)
               {    
                    if (hours==1) {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
                    } else
                    {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
                    }
                    
               }

               var days = Math.floor(hours/24);
               if (days<31)
               {    
                    if (days==1) {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
                    } else
                    {
                              return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
                    }

               }

               var months = Math.floor(days/30);
               if (months<12)
               {
                    if (months==1) {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
                    } else
                    {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
                    }

               }
               
               var years = Math.floor(months/12);    

               if (years==1) {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
               } else
               {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
               }    
          
          },

          getcountry(answer) {               
               return answer['clubCountry' + this.currentLanguageWebsite]
          },

          getprofilepicture(answer) {           
               return 'https://www.hockeydo.com/users/'  + answer.userimagename + '.png';
          },

          getanswers() 
          {     

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getanswers');
               }    


               
               if (this.commentid.id)
               {
                    var post = new Object();
                    post.task = 'getanswers';
                    post.commentid = this.commentid.id;

                    axios.post( this.$store.state.config.apiserver + 'interactions/panels/ShowCommentsCommentAnswers.php',post)
                    .then(response => {
                         
                         this.answers = response.data;
                                 
                         // console.log(response.data);

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });

               }

          }
      

    },

    computed: 
    {
      
          currentLanguageWebsite()
          {
               return this.$store.state.active.languagewebsite;
          },
          activetaskchanges(){
        

               return this.$store.state.active.task;
          }
     

    },

    watch: 
    {
          activetaskchanges() {   
               

               if (this.$store.state.active.task == 'getanswers')
               {

                    if (this.commentid.id==this.$store.state.active.commentid)
                    {
                         this.getanswers();
                         this.setactiveproperty('task','');
                    
                    }
     
          
               }
               
          }

    },
    created () {
       this.getanswers();
    },   
    mounted() 
    {
        // Elke 100 minuten checken of er nog antwoorden binnengekomen zijn.    
        window.setInterval(() => {
        this.getanswers()
        }, 6000000)

    }


  
}
</script>

<style scoped>

.answertext {
     background: #eee; 
     display: inline-block;
     padding: 5px 20px;
     border-radius: 15px;
}

</style>
