<template>


     <div class="view" v-if="group"> 



          <div class="row">


               <div class="col col-12 mb15  ">

                
                    <div v-if="this.$store.state.user">



					



                         <div v-if="this.store.state.we.group.userstatus==''">

                              <a class="button" v-on:click="setactiveproperty('panel','Apply')">{{translate.GROUPINFO_SOLLICITERENKNOP}}</a> 
                         
                         </div>


                    </div>     



                    <div v-if="!this.$store.state.user">
                    
                         <a class="button" v-on:click="setactiveproperty('panel','Login')">{{translate.GROUPINFO_SOLLICITERENKNOP}}</a>
                    
                    </div>


                    <div v-if="this.store.state.we.group.userstatus=='prospect'">
                    
                         {{translate.GROUPINFO_HEEFTGESOLLICITEERD}}
                    
                    </div>


                    <div v-if="this.store.state.we.group.userstatus=='admitted'">
                    
                         {{translate.GROUPINFO_ISTOEGELATEN}}
               
                    </div>

                    <div v-if="this.store.state.we.group.userstatus=='declined'">
                    
                    {{translate.GROUPINFO_ISNIETTOEGELATEN}}
               
                    </div><br>


				{{translate.GROUPINFO}}


                                             


                 


                  

                
                    

               </div>  

          </div>


          
     </div>
     
</template>


<script>

// import axios from "axios";

export default{
    
     data(){
          return {    
               
              
          }
     },
     computed:{    
          
          
         group() {

               return this.$store.state.we.group;

         }
   
       
          
     },

     methods: {

     
        
   
       


     },
     mounted(){

          this.$store.dispatch('fetchgroup');

     }
    
}
</script>

<style scoped>






</style>
