<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
              


               <div class="row panel_header middle">
                    <div class="col col-6">
                         <h1>{{translate.POSTFILTERLANGUAGE_KOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">   
                    


                    <div v-for="language in this.$store.state.static.languages" :key="language.id">    

               

                         <div v-if="selectedlanguagesids.includes(language.id)">

                              <a v-if="language.id==1" class="button mb15" v-on:click="setlanguage(language.id)">{{translate.LANGUAGES_ALLETALEN}}</a>  

                              <a v-if="language.id>1" class="button mb15" v-on:click="setlanguage(language.id)">{{language.name}}</a>                  
                         </div>

                         <div v-else>

                              <a v-if="language.id==1" class="button ghost mb15" v-on:click="setlanguage(language.id)">{{translate.LANGUAGES_ALLETALEN}}</a>    

                              <a v-if="language.id>1" class="button ghost mb15" v-on:click="setlanguage(language.id)">{{language.name}}</a>    
                         </div>     

                    
                    </div>
          
               </div>




          </div>
     </transition>     
  
</template>

<script>





export default {

     data() {
          return{
               panelname: 'PostsFilterLanguages'
         
          
               
          }
     },

 

     methods:{

         
          setlanguage(value)
          {

                
               var selectedlanguagesids = this.$store.state.active.filterlanguagesids.split(',');

               if (value==1) 
               {
                    if (!selectedlanguagesids.includes(value)) 
                    {
                         selectedlanguagesids = ['1'];                     
              

                         
                    } else 
                    {
                         selectedlanguagesids = selectedlanguagesids.filter(function(e) { return e !== '1' })
                         
                    } 
               
               } else 
               {
                    
                    selectedlanguagesids = selectedlanguagesids.filter(function(e) { return e !== '1' })
                   

                    if (!selectedlanguagesids.includes(value)) {


                         if (value==1) 
                         {
                              selectedlanguagesids = ['1'];
                            

                         } else 
                         {

                              selectedlanguagesids.push(value);
                             
                         }

                    } else {                   

                         selectedlanguagesids = selectedlanguagesids.filter(function(e) { return e !== value })
                      
                    }

               }     

 

               selectedlanguagesids = selectedlanguagesids.filter(function (el) {
                    return el != '';
               });



               this.setactiveproperty('filterlanguagesids',selectedlanguagesids.toString());
     



          }
          

     },

     computed: 
     {

          selectedlanguagesids() {                          
               return this.$store.state.active.filterlanguagesids.split(','); 
          },
 

     },

  


  
}
</script>

 
<style scoped>

</style>
