export default {

    
     sethomeproperty(state,payload){

          state.home[payload.property] = payload.value;
          
     },


}