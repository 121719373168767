<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

         

               <div class="row panel_header middle">
                    <div class="col col-6">
                         <h1>{{translate.APPLY_KOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    {{translate.APPLY_INTROTEKST}}

                    <br/>
                    <br/>


                    <div v-if="this.store.state.we.group.userstatus=='prospect'">
                    
                         prospect
                    
                    </div>

                    <div v-else>

                         <a class="button" v-on:click="apply()">{{translate.APPLY_SOLLICITEREN}}</a> 

                    </div>

          
               </div>

              
               

          


          </div>

     </transition>
  
</template>

<script>

import axios from "axios";



export default {



     data() {
          return{
               
               panelname: 'Apply'
          
               
          }
     },
     methods:{

  
          apply() 
          {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('apply');
               }
                    
               var post = new Object();
               post.task = 'apply'; 
               post.groupid = this.$store.state.we.group.id;
               post.hdaccesstoken = this.$cookie.get('hockeydo');

               console.log(post);

               axios.post( this.$store.state.config.apiserver + 'we/panels/apply.php',post)
               .then(response => {
                    
                    if (this.$store.state.config.debug==1) 
                    {
                         console.log(response.data)   
                    }         

          

                    this.$store.dispatch('fetchgroup');
                    this.setactiveproperty('panel','');
   
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });


          }
          

     },

    computed: 
    {


     

    },


  
}
</script>

