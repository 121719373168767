<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel=='Menu'" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
          


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>Menu</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <!-- <a v-on:click="setactiveproperty('panel','Bookmarks')" class="button ghost mb10">{{translate.MENU_OPGESLAGEN}}</a> -->

                    <br/><a v-on:click="setactiveproperty('panel','UserProfile')" class="button ghost mb10 ">{{translate.MENU_INSTELLINGEN}}</a>


                          
                    <br><a class="button ghost" v-on:click="logout()">{{translate.USERPROFILE_LOGOUT}}</a>
                        
          
               </div>



          </div>

     </transition>
  
</template>

<script>





export default {

 
     data() {
          return{
               
               panelname: "Menu"
          
               
          }
     },

     components: {


     },
     methods:{

         

          logout() {

               this.$cookie.remove('hockeydo');

               var host = location.hostname;

               if (host=='www.hockeydo.com') {

                    window.location.replace("https://www.hockeydo.com");

               } else {       
                    
                    
                    host = 'http://' + location.hostname + ':' + location.port;          
           
                    window.location.replace(host);

               }

               

          },
          

     },

    computed: 
    {


  


    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
