<template>

     <transition name="slideup">

              <div v-if="this.$store.state.active.toppanel==panelname" class="panel autoheight">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>


               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.CREATEANSWER_KOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="closetoppanel()"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

               
                    <form @submit="postdata">
                         
                         <div class="row">
                              <div class="col col-12">
                                   <textarea class="" v-model="comment.text" :placeholder="[[translate.COMMENTSCREATEANSWER_SCHRIJFEENANTWOORD]]" />
                                   <button class="submit button floatright" type="submit">{{translate.VERSTUUR}}</button>
                              </div>
                         </div>

                    </form>       

               </div>

          </div>          
              
     </transition>
</template>

<script>

import axios from "axios";



export default {
     
     data() {
        return{
         
          comment:
          {
               text:null,
       
          },
          panelname: 'CreateAnswer'

            
        }
     },
     methods:{

          closetoppanel(){

               this.setactiveproperty('toppanel','');
          },

          postdata(e) 
          {

               e.preventDefault();

               if (this.$store.state.config.debug==1) 
               {
               console.log('createanswer');
               }

               this.comment.task = "createcommentanswer";
               this.comment.state = 1;   
               this.comment.languageid = this.$store.state.user.languagewriteid;
               this.comment.commentid = this.$store.state.active.commentid;
               this.comment.hdaccesstoken = this.$store.state.user.hdaccesstoken;

               //console.log(this.comment);

          
               axios.post(this.$store.state.config.apiserver + 'interactions/panels/CreateAnswer.php',this.comment)
               .then(response => 
               {           
                    console.log(response.data);                      
                    this.comment.text = '';                    
                                                      
                    this.setactiveproperty('toppanel','');
                    this.setactiveproperty('task','getcomments');
                    
               
               })
               .catch(error => 
               {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          },
   

     },
     computed: 
     {

    

          user() {        
              return this.$store.state.user;        
          },
     





     },
 


  
}
</script>

 

