<template>

 
     <Filter />

     <PostsFilter />    
 
     <Posts />

     
</template>


<script>


import Posts from '@/components/posts/Posts.vue'
import Filter from '@/components/common/Filter.vue'
import PostsFilter from '@/components/posts/PostsFilter.vue';


export default{
     name:"View",
     data(){
          return {    
                              
          }
     },
     components: { 
          Posts,
          Filter,
          PostsFilter
     },
     computed:{          
   
     

          
     },
     watch: {



     },


     methods: {




     




     }
}
</script>

<style scoped>






</style>
