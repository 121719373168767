<template>

    


          <div class="row mb5">
               <div class="col-12 horizontalverticalcenter">
                    <img class="profilepicture mb5" :src="profilepicture" :title="name"/>  
               </div>
          </div>     

          <div class="row">
               <div class="col-12 horizontalverticalcenter">
                    <div v-if="vote.vote=='yes'" class="horizontalverticalcenter">
                         <i class="bi bi-hand-thumbs-up-fill tekstgroen"></i>
                    </div>

                    <div v-if="vote.vote=='no'" class="horizontalverticalcenter">
                         <i class="bi bi-hand-thumbs-down-fill tekstrood"></i>
                    </div>
               </div>
          </div>  


     
</template>


<script>


export default{
    
     data(){
          return {    
                              
          }
     },
     props: ["vote"],
     computed:{          
   
      
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.vote.userimagename + '.png';

          },


          name() {
               return this.vote.userFirstname + ' ' + this.vote.userLastname
          }
   

          
     },
 
}
</script>

<style scoped>
     
     .bi {
          position: relative;
          left: -5px;       
     }

</style>


