import {createRouter, createWebHistory} from "vue-router";
import ClubsView from "@/components/clubs/ClubsView.vue";
import PostsView from "@/components/posts/PostsView.vue";
import GamesView from "@/components/games/GamesView.vue";
import WeView from "@/components/we/WeView.vue";
import HockeydoView from "@/components/hockeydo/HockeydoView.vue";
import HomeView from "@/components/home/HomeView.vue";
import store from "@/store/store.js";

const routes = [
	{
		name: "Clubs",
		path: "/clubs/:continent?/:country?/:region?/:club?/:scope?/:sub?/:subsub?",
		component: ClubsView,
	},

	{
		name: "Posts",
		path: "/posts/:continent?/:country?/:region?/:club?/:scope?/:sub?/:subsub?",
		component: PostsView,
	},

	{
		name: "Home",
		path: "/",
		component: HomeView,
	},

	{
		name: "Games",
		path: "/games/:competition?/:game?/:video?/:mode?/:scope?",
		component: GamesView,
	},

	{
		name: "We",
		path: "/we/:group?/:view?",
		component: WeView,
	},
	{
		name: "Hockeydo",
		path: "/hockeydo/:view?",
		component: HockeydoView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	const {name, params} = to;

	const combinedParams = {
		old: {...from.params},
		new: {...params},
	};

	try {
		if (name === "Clubs") {
			// await store.dispatch("handleLocationsRoute", params);

			next();
		} else if (name === "Posts") {
			// await handlePostsRoute(store, params, next);
			next();
		} else if (name === "Home") {
			// await handleHomeRoute(store, params, next);
			next();
		} else if (name === "Games") {
			await store.dispatch("handleGamesRoute", params);

			await store.dispatch("handleRankingsRoute", combinedParams);

			next();
		} else if (name === "We") {
			// await handleWeRoute(store, params, next);
			next();
		} else if (name === "Hockeydo") {
			// await handleHockeydoRoute(store, params, next);
			next();
		} else {
			next();
		}
	} catch (error) {
		next(false);
	}
});

export default router;
