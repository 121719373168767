<template>

     <div>                    
          <Info v-if="view=='info'" />          
     </div>
     
</template>


<script>

import Info from '@/components/hockeydo/views/Info.vue'




export default{

     data(){
          return {    
                              
          }
     },
     components: {
          Info
     },

     computed: {

          view (){
               
               return this.$route.params.view;
   
             
          }


     },

  
}







</script>
