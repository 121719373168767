<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.USERPROFILE_IKLEES}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">


                    <div v-for="language in this.$store.state.static.languages" :key="language">  

                        <span v-if="userlanguagereadids.includes(language.id)">
                            <a v-on:click="setlanguage(language)" class="button mb10">{{language.name}}</a>
                        </span>

                        <span v-else>
                            <a v-on:click="setlanguage(language)" class="button ghost mb10">{{language.name}}</a>
                        </span>        


                    </div>

                    <a v-on:click="closepanel()" class="button floatright">{{translate.SLUIT}}</a>

          
               </div>


           

          </div>

     </transition>
  
</template>

<script>


import axios from "axios";


export default {

 
     data() {
          return{
               
               panelname: 'UserProfileReadLanguages'
          
               
          }
     },


     methods:{

         
  
          setlanguage(language)
          {

            var languageids = this.$store.state.user.languagereadids.split(',');

            // Als hij erin zit weghalen, anders toevoegen

            if (parseInt(language.id) > 1) {
                languageids = languageids.filter(function(item) {
                    return item !== '1';
                })   
            }


            if (languageids.includes(language.id)) {

                languageids = languageids.filter(function(item) {
                    return item !== language.id
                })

            } else
            {
                languageids.push(language.id);
            }

            if (languageids.includes('1')) {
                languageids = ['1'];
      
            } 

     

            if (this.$store.state.config.debug==1) 
            {
            console.log('setuserlanguagesread');
            }    

            var post = new Object();
            post.task = 'setuserlanguagesread';
            post.languageids =  languageids.toString();
            post.hdaccesstoken = this.$cookie.get('hockeydo'); 

          //   console.log(post);

            axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
            .then(response => 
            {
               this.$store.dispatch('fetchuser');                      
               if (this.$store.state.config.debug==1) 
               {
               console.log(response.data);
               }  
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
               

          }
          

     },

    computed: 
    {
   

          userlanguagereadids() 
          {                

               return this.$store.state.user.languagereadids.split(',');
          },

    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
