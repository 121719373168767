<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

         

               <div class="row panel_header middle">
                    <div class="col col-6">
                         <h1>{{translate.VOTE_WEETJEHETZEKER}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div v-if="vote" class="row panel_content ">

                    <div class="col col-12 horizontalverticalcenter">

                         <div class="centertext">

                              <div v-if="vote.vote=='yes'">
                                   <i class="bi bi-hand-thumbs-up-fill bi--groot tekstgroen mb15"></i><br>

                                   <a v-on:click="dovote('yes')"  class="button">{{translate.VOTE_IKWEETHETZEKER}}</a>
                              </div>

                              <div v-if="vote.vote=='no'">
                                   <i class="bi bi-hand-thumbs-down-fill bi--groot tekstrood mb15"></i><br>

                                   <a v-on:click="dovote('no')" class="button">{{translate.VOTE_IKWEETHETZEKER}}</a>
                              </div>

                         </div>     

                    </div>


          
               </div>


          


          </div>

     </transition>
  
</template>

<script>

import axios from "axios";



export default {



     data() {
          return{
               
               panelname: 'WeVote'
          
               
          }
     },
     methods:{

  
          dovote(vote) 
          {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('vote');
               }
                    
               var post = new Object();
               post.task = 'vote'; 
               post.userid = this.$store.state.we.vote.userid; 
               post.groupid = this.$store.state.we.group.id;                         
               post.vote = vote;
               post.hdaccesstoken = this.$cookie.get('hockeydo');

               console.log(post);

               axios.post( this.$store.state.config.apiserver + 'we/panels/vote.php',post)
               .then(response => {
                    
                    if (this.$store.state.config.debug==1) 
                    {
                         console.log(response.data)   
                    }     
                    
                    
                    this.setactiveproperty('task','refreshprospectives');

                    this.setactiveproperty('panel','');
   
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });


          }
          

     },

    computed: 
    {


          vote() {
               return this.$store.state.we.vote;
          },


          

    },


  
}
</script>

