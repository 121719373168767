<template>
	<div class="rankingsmenu">
		<router-link v-if="current.club" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: current.mode, scope: 'club'}}">
			<div class="button" :class="view != 'club' ? 'ghost' : ''">{{ current.club.name_short }}</div>
		</router-link>

		<router-link v-if="current.region" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: current.mode, scope: 'region'}}">
			<div class="button" :class="view != 'region' ? 'ghost' : ''">{{ current.region.name }}</div>
		</router-link>

		<router-link v-if="current.country" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: current.mode, scope: 'country'}}">
			<div class="button" :class="view != 'country' ? 'ghost' : ''">{{ current.country[active.languagewebsite] }}</div>
		</router-link>

		<router-link v-if="current.continent" :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + current.video.nummer, mode: current.mode, scope: 'continent'}}">
			<div class="button" :class="view != 'continent' ? 'ghost' : ''">{{ current.continent[active.languagewebsite] }}</div>
		</router-link>
	</div>
</template>

<script>
import {mapState} from "vuex";
export default {
	data() {
		return {};
	},

	components: {},
	computed: {
		...mapState(["current", "active", "config", "games", "staticdata"]),

		view() {
			return this.$route.params.scope;
		},
	},

	watch: {},

	methods: {},
};
</script>

<style scoped>
.rankingsmenu {
	background: #fff;
	padding: 10px;
	display: flex;
	justify-content: flex-start; /* Zorgt voor gelijke afstand tussen knoppen */
	width: 100%; /* De breedte van de rij */
}

.rankingsmenu .button {
	flex: 1; /* Zorgt ervoor dat elke knop dezelfde breedte heeft */
	margin: 0 5px; /* Optioneel: om wat ruimte tussen de knoppen te hebben */
}
</style>
