import appmutations from './app/app-mutations.js'
import gamesmutations from './games/games-mutations.js' 
import postsmutations from './posts/posts-mutations.js'     
import homemutations from './home/home-mutations.js'  
import wemutations from './we/we-mutations.js'  






export default { 
     ...appmutations, 
     ...gamesmutations,
     ...postsmutations,
     ...homemutations,
     ...wemutations

}