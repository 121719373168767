export default {

    
     addpostsids(state,postids){
     

          state.posts.postids = [...state.posts.postids, ...postids]
     
          
     },

     deletepostsids(state){
     

          state.posts.postids = [];
          state.posts.postbatch = 0;
     
          
     },

     setpostbatch(state,data){

          state.posts.postbatch = data;
          
     },

     setpostloading(state,data){

          state.posts.postloading = data;
          
     },





}