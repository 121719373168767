<template>

     <div class="view"> 

          <div class="row view_header">


               <div class="col col-12">
             
                    <h2>{{translate.GROUPMEMBERS_LEDEN}}</h2>

             
               </div>  

          </div>

          <div class="row">


               <div class="col col-12">
             
               

                    <div v-if="members">

                         <div v-for="member in members" :key="member.id">               
                                    <Member v-bind:member="member" />
                         </div>

                    </div>


               </div>  

          </div>


          
     </div>
     
</template>


<script>
import axios from "axios";
import Member from '@/components/we/views/GroupMembersMember.vue'

export default{
    
     data(){
          return {    
      
               members: null           
               
          }
     },
     components: 
     {
          Member
     },
     computed:{          
   

          
     },
     watch: {



     },


     methods: {

          getmembers() {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getgroupmembers');
               }
                    
               var post = new Object();
               post.task = 'getgroupmembers';
               post.groupid = this.$store.state.we.group.id;   
               post.hdaccesstoken = this.$cookie.get('hockeydo');                

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'we/views/GroupMembers.php',post)
               .then(response => {
                    
                    this.members = response.data;                     
                    // console.log(response.data);
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });




          }


  
       



     },
     mounted() {

          this.getmembers();

     }
}
</script>

<style scoped>






</style>
