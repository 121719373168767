<template>

     <div class="view box-shadow feeditem " v-if='item'>     


         
          <div class="postheader">

               <div class="links"> 

                    <div v-if="item.logo">
                         <img class="profilepicture" :src="'https://www.hockeydo.com/admin/' + item.logo"/>
                    </div>

                    <div v-else class="nologo" :style="{background: item.color}">
                         {{this.item.abbr}}
                    </div>
                     
               </div>     

               <div class="midden">
                    
                    <div class="heelklein tekstgrijs">      
                         <span class="dik tekstzwart">{{ item.name }} </span>  {{ item.id }} &bull;  {{ calctime }} <span v-if="country">&bull;</span> {{ country }} <span v-if="continent">&bull;</span> {{ continent}}
                    </div>                   

                    
               </div> 

               <div class="rechts">
                    <i v-on:click="openitemmenu()" class="bi bi-three-dots tekstgrijs floatright"></i>
               </div>


          </div>
          
          <h2>{{ title }}</h2>
          {{ description }}

   

          <div v-if="youtubesrc" class="waiting row">

               <div class="youtubecontainer">

                     <iframe :width="this.$store.state.active.windowwidth*0.47"  :src="youtubesrc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 

               </div>

      
          </div>

          <a v-if="item.link"  class="leesverder"  target="_BLANK" :href="item.link"><span>{{ translate.FEEDITEM_LEESVERDER }}</span></a>
 

         

          <div>

               <Footer v-bind:item="item" />
               
          </div>
          

     </div>

     <div v-else class="loadingpost box-shadow">

          <div class="header">
               <div class="picture"></div>
               <div class="regel"></div>
               <div class="regel"></div>
               
          </div>

          <div class="regel"></div>
          <div class="regel"></div>
          <div class="regel"></div>    

          </div>    

     
     
</template>


<script>

import Footer from '@/components/interactions/interactionFooter.vue';


import axios from "axios";

export default{

     inheritAttrs:false,
    
     data(){
          return {    
              item: [],                
          }
     },
     props: ["itemid"], 

     components: {
         Footer,
    
     },
     methods: {

          getitem(){
              
        
               

               if (this.itemid.type=="feeditem")
               {

                    if (this.itemid.id)
                    {
                         

                         if (this.$store.state.config.debug==1) 
                         {
                              console.log('getfeeditem: ' + this.itemid.id);
                         }
                              
                         var post = new Object();
                         post.task = 'getfeeditem';
                         post.itemid = this.itemid.id;
                         post.hdaccesstoken = this.$cookie.get('hockeydo');
                         

                         // console.log(post);
                      

                         axios.post( this.$store.state.config.apiserver + 'home/HomeViewFeedItem.php', post)
                         .then(response => {
                              
                                     
                              // console.log(response.data);  
                                            
                              this.item = response.data;                          

                         
                         })
                         .catch(error => {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                         });


                         }

                                        
                    
                    }


          },

          openitemmenu(){     

               if (this.loggedin){

                    this.setactiveproperty('item',this.item);
                    this.setactiveproperty('panel','ItemMenu');

               } else {

                    this.setactiveproperty('panel','Login');
               }

       

          },




     },
     computed:{     

          title(){

               if (this.item.title) {
                    
                    var title = this.item.title.replace(/\?/g, '');
                    return title;
                    
               } else {


                    return null;
               }

              
          },
          description(){

               if (this.item.description) {

                    var description = this.item.description.replace(/\?/g, '');
                    return description;
               } else {

                    return null;
               }

           

          },

          task(){
               return this.$store.state.active.task;
          },

          country() {
               return this.item['country_' + this.languagewebsite];

          },

          continent() {
               return this.item['continent_' + this.languagewebsite];

          },
          youtubesrc(){

               if (this.item.video) {

                    var src = this.item.video.split('=')['1'];

                    return 'https://www.youtube.com/embed/' + src;

               } else {


                    if (this.item.link)
                    {
                         // if (this.item.link.includes('youtube')){

                         //      src = this.item.link.split('=')['1'];   
                         //      return 'https://www.youtube.com/embed/' + src;

                         // } else {

                         //      return null

                         // }
                      
                         return null


                    } else {

                         return null

                    }

                    
               }

     
          },



          calctime() 
          {
               var now = Date.now() /1000 |0             
               var created = parseInt((new Date(this.item.timestamp).getTime() / 1000).toFixed(0)); 
               
          
               
               var diff = now-created;

               var minutes = Math.floor(diff/60);         
               if (minutes<61) {
                    
                    return minutes + ' ' + this.$store.state.translate.POSTPOST_MINUTEN;

               } 
               

               var hours = Math.floor(minutes/60);
               if (hours<25)
               {    
                    if (hours==1) {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UUR;
                    } else
                    {
                         return hours + ' ' + this.$store.state.translate.POSTPOST_UREN;
                    }
                    
               }

               var days = Math.floor(hours/24);
               if (days<31)
               {    
                    if (days==1) {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAG;
                    } else
                    {
                         return days + ' ' + this.$store.state.translate.POSTPOST_DAGEN;
                    }

               }

               var months = Math.floor(days/30);
               if (months<12)
               {
                    if (months==1) {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAAND;
                    } else
                    {
                         return months + ' ' + this.$store.state.translate.POSTPOST_MAANDEN;
                    }

               }
               
               var years = Math.floor(months/12);    

               if (years==1) {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAAR;
               } else
               {
                    return years + ' ' + this.$store.state.translate.POSTPOST_JAREN;
               }
               
             



          },
          
          
     },
     watch: {

          task(){

           

               if (this.$store.state.active.task=="refresh") {



                    if (this.$store.state.active.taskitemtype == this.item.type) {

                         if (this.$store.state.active.taskitemid==this.item.id) {

                              this.getitem();

                              this.setactiveproperty('task',null);
                              this.setactiveproperty('taskitemtype',null);
                              this.setactiveproperty('taskitemid',null);

                         }

                    
                    }
   

               }

               

          }



     },
     mounted()
     {            
          this.getitem();
 
     }, 


  
}
</script>

<style scoped>

.nologo {
     display: block;
     height: 35px;
     width: 35px;
     border-radius: 50%;
     color: #fff;
     display: flex;
  justify-content: center;
  align-items: center;

}

.youtubecontainer {
width: 100%;
height: 0;
padding-bottom: 50%;
overflow: hidden;
position: relative;
margin-top: 15px;
margin-bottom: 15px;
}

.youtubecontainer iframe {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
height: 100%;
}

.waiting {

    background: #eee;
    animation: glow 1s infinite alternate; 
}

h2{
    margin-bottom:10px; 
}

.leesverder {
width: 100%;     
display: block;
text-align: right;
margin-top: 10px;
margin-bottom: 10px;
}

.leesverder span{
background-color: var(--groen);
color: #fff;  
border-radius: 10px;
padding: 2px 8px;  
font-size: 14px;
}

</style>

