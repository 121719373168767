<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel=='RankingsFilter'" class="panel autoheight">

     

               <div class="row panel_header mb15 middle">
                    <div class="col col-6">
                         <h1>{{translate.RANKINGSFILTER_FILTER}}</h1>
                    </div>    
                    <div class="col-6">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-chevron-down tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div class="row mb15">

                       
                         <div class="col-2">
                              <Toggle 
                                   v-model="showusers" 
                               
                               />
                         </div>

                         <div class="col-6">
                              {{translate.RANKINGSFILTER_SHOWUSERS}}
                         </div>


                    </div>



                    <div class="row">

                       
                         <div class="col-2">
                              <Toggle 
                                   v-model="showreferees" 
                        
                               />
                         </div>

                         <div class="col-6">
                              {{translate.RANKINGSFILTER_SHOWREFEREES}}
                         </div>


                    </div>

                   

               

          
               </div>

               <div class="panel_footer">

               </div>

          </div>

     </transition>     
  
</template>

<script>

// https://github.com/vueform/toggle

import Toggle from '@vueform/toggle'




export default {

 
     data() {
          return{

                                     
               
          }
     },
     components: {
      Toggle,
     },
 
     methods:{

          
          closepanel()
          {
               this.$store.dispatch('taskShowPanel',null);

          }
          

     },

    computed: 
    {



          translate() 
          {        
               return this.$store.state.translate;        
          },


          showusers: 
          {
               get() 
               {
                    return this.$store.state.active.rankingsShowUsers;
               },
               set(showusers)
               {                    
                    this.setactiveproperty('rankingsShowUsers',showusers);           

               }

          },
          showreferees: 
          {
               get() 
               {
                    return this.$store.state.active.rankingsShowReferees;
               },
               set(showreferees)
               {                    
                    this.setactiveproperty('rankingsShowReferees',showreferees);                   

               }

          },


  

    },



  
}
</script>

