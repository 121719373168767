<template>

     <transition name="slideup">

          <div v-if="this.store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

          

               <div class="row panel_header middle">

                    <div class="col col-10">

                

                    </div>


                    <div class="col-2 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               

               <div class="panel_content">

                    <div class="searchbox">
                         <input data-lpignore="true" :value='searchstring' @input='evt=>searchstring=evt.target.value' :placeholder="translate.SEARCH_ZOEKOPCLUB">                   
                    </div>
                    
                    <div v-if="searchstring.length>0" id="SearchResults">

                         <div v-if="searchresults"  >
                              <div v-for="club in searchresults" :key="club">

                                   <div v-on:click="setClub(club.id)" class="club"><span class="dik ">{{ club.name_short }} </span><span class="tekstgrijs"> - {{ club.place}} - {{ club.nl}} </span></div>            
                              </div>
                         </div>
                         <div v-else class="loadingpost">
                              <div class="regel"></div>        
                              <div class="regel"></div>
                              <div class="regel"></div>

                         </div>     

                    </div>

                    <a v-on:click="setactiveproperty('panel','FilterAreaArea')" class="addpost blauw"><i class="bi bi-globe"></i></a>

               </div>



          </div>

     </transition>
  
</template>

<script>

import axios from "axios";



export default {

 
     data(){
     return{
          searchresults : null,
          searchstring : '',
          panelname: 'FilterArea'

     }
     },

     methods:{

  
  
          setClub(clubid)
          {

          this.searchstring = '';
          this.clubs = null;

          if (this.$store.state.config.debug==1) {
               console.log('getclub');
          }

          var post = new Object();
          post.task = 'getclub';
          post.clubid = clubid;         

          axios.post( this.$store.state.config.apiserver + 'clubs/panels/FilterArea.php', post)
          .then(response => 
          {      
               var club = response.data;         
               console.log(response.data);

  
               if (this.$route.name == 'Clubs') {
                    this.$router.push({ name: 'Clubs', params:{ continent: club.continentslug, country: club.countryslug,region: club.regionslug,club: club.slug,scope: 'clubinfo',sub: 'info', subsub: 'info' } })        
               
               }
         
               
               
               this.setactiveproperty('panel','');
     
               // http://localhost:8080/posts/europe/the-netherlands/midden-nederland/shinty/club/posts/all
          
          })
          .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });
     }


          

     },

    computed: 
    {

          currentFilterRegion() {

               return this.$store.state.current.filterRegion;

          }


     },

     watch: {
     
          searchstring: function (value) 
          {

               if (value)
               {


                    if (this.$store.state.config.debug==1) {
                         console.log('search');
                    }
          

                    var post = new Object();
                    post.task = 'searchclubs';
                    post.searchstring = value;         

                    axios.post( this.$store.state.config.apiserver + 'clubs/panels/FilterArea.php', post)
                    .then(response => 
                    {                         
                         this.searchresults = response.data;
                    })
                    .catch(error => {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });

               
               } else {
               
               this.clubs = '';

          }

     
     }
},




  
}
</script>

 
<style scoped>

.searchbox{
width: 100%;
position: relative;
height: 45px;
top: 3px;

}

 .searchbox i {
position:absolute;     
right: 15px;
top: 6px;
font-size: 28px;
}

 input {
width: 100%;
}

.bi-globe {

position: fixed;
bottom: 70px;
right: 30px;
padding: 15px;
background: var(--blauw);
color: var(--wit);
font-size: 75px;
border-radius: 50px;
}



 #SearchResults .club {
padding: 10px 0px;  
transition: all 0.4s;
border-bottom: 1px solid #eee;  
cursor: pointer;
}

 #SearchResults .club:hover{
background: #f8f8f8;     
}

      
      
/* SM */
@media (min-width: 576px) {  
 
}
 
/* MD */
@media (min-width: 768px) {  

    
 
}
 
/* LG */
@media (min-width: 992px) { 


 
}
 
/* XL */
@media (min-width: 1200px) {  
    
}      

</style>