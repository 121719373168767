<template>


     <transition name="slideup">

          <div v-if="this.$store.state.active.panel=='Bookmarks'" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>        


               <div class="row panel_header middle">

                    
                   
                    <div class="col col-6">
                         <h1>{{translate.BOOKMARKS_OPGESLAGEN}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>  

               <div class="row">

                         <div class="panel_menu"> 
                   

                              <a v-if="postbookmarkids && postbookmarkids.length > 0" v-on:click="view = 'posts'"  :class="{'active': view=='posts'}" class="panel_menu_item">{{translate.BOOKMARKS_POSTS}}</a>
                              <a v-if="clubbookmarkids && clubbookmarkids.length > 0" v-on:click="view = 'clubs'"  :class="{'active': view=='clubs'}" class="panel_menu_item">{{translate.BOOKMARKS_CLUBS}}</a>
                              <a v-if="userbookmarkids && userbookmarkids.length > 0" v-on:click="view = 'users'"  :class="{'active': view=='users'}" class="panel_menu_item">{{translate.BOOKMARKS_USERS}}</a>
                              <a v-if="feeditemsbookmarkids && feeditemsbookmarkids.length > 0" v-on:click="view = 'feeditems'"  :class="{'active': view=='feeditems'}" class="panel_menu_item">{{translate.BOOKMARKS_FEEDS}}</a>
                 
                         
                         </div>     
                    
               </div>


               <div class="panel_content">


                    <div v-if="view == 'posts'">

                    
                         <div v-for="postbookmarkid in postbookmarkids" :key="postbookmarkid.id">     
                              
                           
                              <BookmarksPosts v-bind:postid="postbookmarkid.itemid" />


                         </div>

                    </div>


                    <div v-if="view =='clubs'">

                         <div v-for="clubbookmarkid in clubbookmarkids" :key="clubbookmarkid.id">                                               


                              <BookmarksClubs v-bind:id="clubbookmarkid.itemid" />


                         </div>

                    </div>

             
                    <div v-if="view=='users'">

                         <div v-for="userbookmarkid in userbookmarkids" :key="userbookmarkid.id">             

                              <BookmarksUsers v-bind:userid="userbookmarkid.itemid"/>
                         
                         </div>

                    </div>


                    <div v-if="view=='feeditems'">

                         <div v-for="feeditemsbookmarkid in feeditemsbookmarkids" :key="feeditemsbookmarkid.id">             

     

                              <BookmarksFeeditems v-bind:feeditemid="feeditemsbookmarkid.itemid"/>

                         </div>

                    </div>

                   

                  


                   

               

                

               </div>     

               


     

               
          </div>
     </transition>     
     
</template>


<script>

import axios from "axios";

import BookmarksPosts from '@/components/users/panels/BookmarksPosts.vue'
import BookmarksClubs from '@/components/users/panels/BookmarksClubs.vue'
import BookmarksUsers from '@/components/users/panels/BookmarksUsers.vue'
import BookmarksFeeditems from '@/components/users/panels/BookmarksFeeditems.vue'

export default{
     name:"PostsHeader",
     data(){
          return {    
               view: 'posts',
               panelname: 'Bookmarks',
               bookmarkids: null
          }
     },
     components:{
          BookmarksPosts,
          BookmarksClubs,
          BookmarksUsers,
          BookmarksFeeditems

     },
     methods:{

          getbookmarkids(){


               if (this.$store.state.config.debug==1) 
               {
                    console.log('getbookmarksids');
               }    
               
      
               var post = new Object();
               post.task = 'getbookmarkids';
               post.hdaccesstoken = this.$cookie.get('hockeydo');

           

               axios.post( this.$store.state.config.apiserver + 'users/panels/Bookmarks.php', post)
               .then(response => {

                    this.bookmarkids = null;

                    this.bookmarkids = response.data;
                    // console.log(response.data);
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });


          }

     },
     computed:{   
          
          userbookmarkids(){

               if (this.bookmarkids){
                    return this.bookmarkids.filter(obj => obj.itemtype === 'user');
               } else {

                    return null
               }
          },

          feeditemsbookmarkids(){

               if (this.bookmarkids){
                    return this.bookmarkids.filter(obj => obj.itemtype === 'feeditem');
               } else {

                    return null
               }
          },

          clubbookmarkids(){

               if (this.bookmarkids){
                    return this.bookmarkids.filter(obj => obj.itemtype === 'club');
               } else {

                    return null
               }
          },
          postbookmarkids(){

          if (this.bookmarkids){
               return this.bookmarkids.filter(obj => obj.itemtype === 'post');
          } else {

               return null
          }
          },


   
      
          openpanel() 
          {              
               return this.$store.state.active.panel             
          },   

          
     },
     watch: 
     {
          openpanel(){

               if (this.$store.state.active.panel=='Bookmarks') {
                    this.getbookmarkids();
                    console.log('bob');
               } 
         

          }
     },
     

}
</script>

