<template>


     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
          

               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.ALERTS_MELDINGEN}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 

               </div>   

               <div class="panel_content" >

                    <div v-for="notification in notifications" :key="notification.id">               

                         <Notification v-bind:notification="notification"/>
                    </div>

               </div>     



          </div>
     </transition>     
  
</template>

<script>

import axios from "axios";
import Notification from '@/components/users/panels/NotificationsNotification.vue';


export default {

     name: 'Notifications',

     data() {
          return{
               
               notifications:null,
               panelname: 'Notifications'
                 
          }
     },
     components: {
          Notification
     },
     methods:{

          getnotifications()  
          {
             

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getnotifications');
               }    
               
      
               var post = new Object();
               post.task = 'getalerts';
               post.hdaccesstoken =  this.$cookie.get('hockeydo');


               axios.post( this.$store.state.config.apiserver + 'users/panels/Notifications.php', post)
               .then(response => {

                    var data = response.data;

                    //  console.log(data);
      
                    data.sort(function(a, b) {
                         return parseFloat(b.unixtime) - parseFloat(a.unixtime);
                    });

                   

                    this.notifications = data;





               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },
          setlastseenmessage() {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('setlastseenmessage');
               }    



               var post = new Object();
               post.task = 'setlastseenmessage';       
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/Notifications.php', post)  
               .then(response => 
               {                   
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response);
                    }                  

                    // this.$store.dispatch('trigger','getuser');
                   

                    
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });
               

          },
          closepanel()
          {
               
               this.setlastseenmessage();
               this.$store.dispatch('taskShowPanel',null);

          },
          

     },

    computed: 
    {

          panelchanges(){

               return this.$store.state.active.panel;
          }
    


    },
    watch:{

          panelchanges(){


               if (this.$store.state.active.panel=="Notifications"){

             

                    this.getnotifications();

               }

               

          }

    }

  
}
</script>

