<template>
	<div v-if="current">
		<GamesHeader />

		<div v-if="current.mode == 'game'">
			<Play v-if="current.game.status == 'play'" />
			<Chat v-if="current.game.status == 'chat'" />
			<Wait v-if="current.game.status == 'wait'" />
		</div>

		<div v-if="current.mode == 'ranking'">
			<RankingsMenu />
			<Rankings />
		</div>
	</div>
</template>

<script>
import GamesHeader from "@/components/games/GamesHeader.vue";
import Play from "@/components/games/views/Play.vue";
import Chat from "@/components/games/views/Chat.vue";
import Wait from "@/components/games/views/Wait.vue";
import Rankings from "@/components/games/views/Rankings.vue";
import RankingsMenu from "@/components/games/views/RankingsMenu.vue";

import {mapState} from "vuex";

export default {
	data() {
		return {
			temp: null,
		};
	},
	components: {
		GamesHeader,
		Play,
		Chat,
		Wait,
		Rankings,
		RankingsMenu,
	},

	computed: {
		...mapState(["current", "competitions", "usergame"]),
	},
	watch: {},
	mounted() {},
};
</script>
