<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1> {{translate.USERPROFILE_TAALVANDEWEBSITE}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="language in languageswebsite" :key="language">  

                         <a v-on:click="setlanguage(language)" class="button ghost mb10">{{language}}</a>
                        

                    </div>


                    

          
               </div>





           

          </div>

     </transition>
  
</template>

<script>


import axios from "axios";


export default {

 
     data() {
          return{
               
               languageswebsite:  ["en","nl","de","fr","it","pt","es"],
               panelname: 'UserProfileWebsiteLanguages'
          
               
          }
     },


     methods:{

   
          setlanguage(language)
          {

               if (this.$store.state.config.debug==1) 
               {
               console.log('setuserlanguagewebsite');
               }    

               var post = new Object();
               post.task = 'setuserlanguagewebsite';
               post.language =  language;
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               // console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
               .then(response => 
               {
               
               
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }  
                    
                    this.$store.dispatch('fetchuser');  
                    this.setactiveproperty('toppanel','');
                
                    
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          }
          

     },

    computed: 
    {
          userlanguagewebsite() {   
                    return this.$store.state.user.languagewebsite;
          },

   


    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
