import appgetters from './app/app-getters.js'
import gamesgetters from './games/games-getters.js' 
import postsgetters from './posts/posts-getters.js'     
import homegetters from './home/home-getters.js'   




export default { 
               ...appgetters, 
               ...gamesgetters,
               ...postsgetters,
               ...homegetters
          
          
          }
