<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.toppanel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Toppanel | {{ panelname }}</div>

			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.FILTER_KIESREGIO }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('toppanel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="panel_content" v-if="regions">
				<div v-for="region in regions" :key="region.id">
					<a class="ghost button mb10" v-on:click="setregion(region)">{{ region.name }}</a>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import {mapState} from "vuex";

export default {
	data() {
		return {
			panelname: "FilterAreaAreaRegions",
		};
	},

	components: {},
	methods: {
		setregion(region) {
			const clubsInRegion = this.staticdata.clubs.filter((club) => club.region_id === region.id);

			this.setmapsproperty("clubs", clubsInRegion);

			this.setmapsproperty("region", region);
			this.setmapsproperty("club", null);

			this.setactiveproperty("toppanel", "");
		},
	},

	computed: {
		...mapState(["staticdata", "active", "maps"]),
		regions() {
			const countryId = this.maps.country.id;
			const regions = this.staticdata.ContinentsCountriesRegions.filter((item) => item.country.id === countryId).map((item) => ({
				id: item.id,
				name: item.name,
				slug: item.slug,
			}));

			return regions;
		},
	},

	watch: {},
};
</script>

<style scoped></style>
