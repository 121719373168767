
import axios from "axios";

import store from '../store';

export default {


    

     fetchgroup({commit})
     {
          

        

               if (store.state.config.debug==1) 
               {
                    console.log('fetchgroup');
               }


           
               var post = new Object();
               post.task = 'fetchgroup';               
             
               post.groupslug= store.state.route.group;   

               if (store.state.user){
                    post.hdaccesstoken = store.state.user.hdaccesstoken;
               }
                         

               // console.log(post);

               

               axios.post( store.state.config.apiserver + 'we-actions.php',post)
               .then(response => {

                    // console.log(response.data.debug);


                    // console.log(response.data.group);
                    
                    commit('setgroup',response.data.group); 
               
          
               
               
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          


     },
     
     


     
     
}


