<template>
	<transition name="slideup">
		<div v-if="this.store.state.active.panel == panelname" class="panel">
			<div v-if="this.store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col-6 middle">
					<h1>{{ translate.USERPROFILE_JOUWPROFIEL }}</h1>
				</div>
				<div class="col-6 middle-right">
					<div v-if="formok">
						<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
					</div>
				</div>
			</div>

			<div class="panel_content">
				<div class="row mb15">
					<div class="col-12 dik">
						<h2>{{ translate.USERPROFILE_TAALINSTELLINGEN }}</h2>
					</div>
				</div>

				<div class="row mb5">
					<div class="col-12 dik">
						{{ translate.USERPROFILE_TAALVANDEWEBSITE }}
					</div>
				</div>
				<div class="row mb15">
					<div class="col-12">
						<div v-if="languagewebsite">
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileWebsiteLanguages')">{{ languagewebsite }}</a>
						</div>

						<div v-else>
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileLanguages')">{{ translate.KIES }}</a>
						</div>
					</div>
				</div>

				<div class="row mb5">
					<div class="col-12 dik">
						{{ translate.USERPROFILE_IKSCHRIJF }}
					</div>
				</div>
				<div class="row">
					<div class="col-12 mb15">
						<div v-if="userLanguageWrite">
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileWriteLanguages')">{{ userLanguageWrite.name }}</a>
						</div>

						<div v-else>
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileWriteLanguages')">{{ translate.KIES }}</a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 dik mb5">
						{{ translate.USERPROFILE_IKLEES }}
					</div>
				</div>
				<div class="row">
					<div class="col-12 mb15">
						<div v-if="userLanguagesRead">
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileReadLanguages')">{{ userLanguagesRead }}</a>
						</div>

						<div v-else>
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileReadLanguages')">{{ translate.KIES }}</a>
						</div>
					</div>
				</div>

				<div class="row mb15 pt15">
					<div class="col-12 dik">
						<h2>{{ translate.USERPROFILE_CLUBINSTELLINGEN }}</h2>
					</div>
				</div>

				<div class="row">
					<div class="col-12 dik mb5">
						{{ translate.USERPROFILE_OPMIJNCLUB }}
					</div>
				</div>

				<div class="row">
					<div class="col-12 mb15">
						<div v-if="useractivity">
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileActivities')">{{ useractivity }}</a>
						</div>

						<div v-else>
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileActivities')">{{ translate.KIES }}</a>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12 dik mb5">
						{{ translate.USERPROFILE_OPMIJNCLUBBENIKOOK }}
					</div>
				</div>

				<div class="row">
					<div class="col-12 mb15">
						<div v-if="activeuserfunctions">
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileFunctions')">{{ activeuserfunctions }}</a>
						</div>

						<div v-else>
							<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfileFunctions')">{{ translate.KIES }}</a>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12 dik mb5">
						{{ translate.USERPROFILE_LOCATIONS }}
					</div>
				</div>

				<div class="row">
					<div class="col-12 mb15">
						<a class="select" v-on:click="setactiveproperty('toppanel', 'UserProfilelocations')">{{ location }}</a>
					</div>
				</div>

				<div class="row mb60">
					<div class="col-6">
						<a class="button ghost" v-on:click="logout()">{{ translate.USERPROFILE_LOGOUT }}</a>
					</div>

					<div class="col-6 alignright">
						<div v-if="formok">
							<a v-on:click="setactiveproperty('panel', '')" class="button">{{ translate.USERPROFILE_SLUIT }}</a>
						</div>

						<div v-else>
							<a class="button grijs">{{ translate.USERPROFILE_ALLEVELDEN }}</a>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<a v-on:click="setactiveproperty('toppanel', 'UserProfileRemoveaccount')" class="button ghost fr">{{ translate.USERPROFILE_DELETEACCOUNT }}</a>
					</div>
				</div>
			</div>
		</div>
		<!-- panel -->
	</transition>
</template>

<script>
export default {
	data() {
		return {
			panelname: "UserProfile",
		};
	},

	computed: {
		location() {
			var location = this.$store.state.static.locations.filter((x) => x.id === this.$store.state.user.locationid)[0];

			return location[this.$store.state.active.languagewebsite];
		},

		activeuserfunctions() {
			if (this.$store.state.user.userfunctionids) {
				var userfunctionsids = this.$store.state.user.userfunctionids.split(",");

				var lang = this.$store.state.active.languagewebsite;

				var userfunctions = "";

				this.$store.state.static.userfunctions.forEach(function (userfunction) {
					if (userfunctionsids.includes(userfunction.id)) {
						userfunctions += userfunction[lang] + " | ";
					}
				});

				if (userfunctions && userfunctions.slice) {
					return userfunctions.slice(0, -3);
				} else {
					return null;
				}
			} else {
				return null;
			}
		},

		useractivity() {
			var id = this.$store.state.user.useractivityid;

			const activity = this.$store.state.static.useractivities.find(function (item) {
				return item.id === id;
			});

			if (activity) {
				return activity[this.$store.state.user.languagewebsite];
			} else {
				return "";
			}
		},

		userLanguagesRead() {
			var languagereadids = this.$store.state.user.languagereadids;

			var langs = "";

			this.$store.state.static.languages.forEach(function (language) {
				if (languagereadids.includes(language.id)) {
					langs += language.name + " | ";
				}
			});

			if (langs && langs.slice) {
				return langs.slice(0, -2);
			} else {
				return "";
			}
		},

		userLanguageWrite() {
			var languageid = this.$store.state.user.languagewriteid;
			const lang = this.$store.state.static.languages.find(function (language) {
				return language.id === languageid;
			});

			return lang;
		},

		formok() {
			var formok = true;

			//console.log(this.$store.state.user.userfunctionids);

			if (this.$store.state.user.useractivityid < 1) {
				formok = false;
			}

			if (this.$store.state.user.userfunctionids == "") {
				formok = false;
			}

			if (this.$store.state.user.locationid < 1 || typeof this.$store.state.user.locationid == "undefined") {
				formok = false;
			}

			if (this.$store.state.user.languagewriteid < 1) {
				formok = false;
			}

			// if (this.$store.state.user.languagereadids.length == 0 ){

			//      formok = false;
			// }

			if (this.$store.state.user.languagewebsite == "") {
				formok = false;
			}

			return formok;
		},

		clubLogoUrl() {
			if (this.$store.state.user.club) {
				return this.$store.state.config.logosurl + this.$store.state.user.club.id + ".png";
			} else {
				return "";
			}
		},
		userclubcountry() {
			return this.$store.state.user.club["country_" + this.languagewebsite];
		},
		userclubcontinent() {
			return this.$store.state.user.club["continent_" + this.languagewebsite];
		},
		numberofdays() {
			var datechanged = parseInt((new Date(this.$store.state.user.clublastchanged).getTime() / 1000).toFixed(0));
			var now = Math.round(new Date().getTime() / 1000);
			return 365 - Math.floor((now - datechanged) / 60 / 60 / 24);
		},
		languages() {
			return this.$store.state.languages;
		},
	},

	methods: {
		logout() {
			this.$cookie.remove("hockeydo");

			var host = location.hostname;

			if (host == "www.hockeydo.com") {
				window.location.replace("https://www.hockeydo.com");
			} else {
				host = "http://" + location.hostname + ":" + location.port;

				window.location.replace(host);
			}
		},
	},
};
</script>
