<template>
	<div class="view">
		<div class="row view_header">
			<div class="col-9 middle">
				<h1 v-if="rankingnumber > 0">{{ translate.RANKINGS_RANKINGVOOR }} {{ rankingnumber }} {{ translate.RANKINGS_RANKINGNA }} {{ name }}</h1>
				<h1 v-else>{{ translate.RANKINGS_RANKING }} {{ name }}</h1>
			</div>

			<div class="col-3 middle-right">
				<a v-on:click="setactiveproperty('panel', 'RankingsFilter')" class="button ghost">{{ translate.GAMES_FILTER }}</a>
			</div>
		</div>

		<div v-if="rankings">
			<div v-for="ranking in rankings" :key="ranking.id">
				<Ranking :ranking="ranking" />
			</div>
		</div>

		<div v-else>
			<h2>{{ translate.RANKINGS_NORANKINGS }}</h2>
			<br />
		</div>
	</div>
</template>

<script>
// import axios from "axios";
import Ranking from "@/components/games/views/RankingsRanking.vue";

import {mapState} from "vuex";

export default {
	data() {
		return {
			response: null,
			rankingspresent: null,
			details: null,
		};
	},
	components: {
		Ranking,
	},

	methods: {},

	computed: {
		...mapState(["current", "active", "config", "games", "rankings"]),
		name() {
			// console.log("BOB" + this.$route.params.sub4);

			// 			if (this.$route.params.scope == "club") {
			// 				return this.$store.state.current.club.name_long;
			// 			}
			//
			// 			if (this.$route.params.scope == "region") {
			// 				return this.$store.state.current.club.region;
			// 			}
			//
			// 			if (this.$route.params.scope == "country") {
			// 				return this.$store.state.current.country[this.$store.state.active.languagewebsite];
			// 			}
			//
			// 			if (this.$route.params.scope == "continent") {
			// 				return this.$store.state.current.continent[this.$store.state.active.languagewebsite];
			// 			}
			//
			// 			if (this.$route.params.scope == "world") {
			// 				return this.translate.RANKINGS_DEWERELD;
			// 			}

			return "";
		},
		routechanges() {
			return this.$route.params;
		},
		rankingnumber() {
			if (this.$store.state.user) {
				var userid = this.$store.state.user.id;

				var teller = 0;
				var number = "";

				if (this.rankings) {
					this.rankings.forEach(function (ranking) {
						teller++;
						if (ranking.id == userid) {
							number = teller;
						}
					});
				}

				return number;
			} else {
				return null;
			}
		},
	},
	watch: {},

	mounted() {
		this.$store.dispatch("calculatepoints");
	},
};
</script>

<style scoped></style>
