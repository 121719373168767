import {createStore} from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default createStore({
	state: {
		config: {
			apiserver: "https://www.hockeydo.com/api/",
			logosurl: "https://www.hockeydo.com/logos/",
			debug: 1,
		},

		active: {
			postsloading: false,
			rankingsShowReferees: true,
			rankingsShowUsers: true,
		},
		current: {
			mode: "game",
		},

		games: {},
		home: {},
		route: {},
		posts: {
			postids: [],
			postbatch: 0,
			postloading: false,
		},
		static: {},
		maps: {},
		staticdata: {},
		task: {},
		user: {},
		we: {},
	},

	getters,
	mutations,
	actions,
});
