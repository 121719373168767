<template>




     <div v-if="prospectivedetails" class="view_subview" > 

    

          <div class="row mb20">

               <div class="col col-9 verticalcenter">          

                    <img class="profilepicture" :src="profilepicture"/>


                    <div class="klein tekstgrijs">
                         <span class="dik tekstzwart">  {{prospectivedetails.userFirstname}} {{prospectivedetails.userLastname}}</span>
                         &bull; {{prospectivedetails.clubNamelong}} 
                    </div>

               </div>

               <div class="col col-3 verticalcenter horizontalright">
                        
       
               
               </div>     


        
          </div>

          <div v-if="votes" >
  


                    <div class="row">

                         <div class="col-11 offset-1">


                              <div class="row mb15">
                                   <div v-for="vote in votes" :key="vote.id">  
                                             <Votes v-bind:vote="vote" />                                                                        
                                   </div>
                              </div>

                         </div>

                      
                         <div class="col col-12 ">
                                   <a v-if="usercanvote" v-on:click="vote('no')" class="button rood floatright"><i class="bi bi-hand-thumbs-down-fill"></i> {{translate.GROUP_NEE}}</a>                     
                                   <a v-if="usercanvote" v-on:click="vote('yes')" class="button groen floatright mr15"><i class="bi bi-hand-thumbs-up-fill"></i> {{translate.GROUP_JA}}</a>
                         </div>   

                   



                    </div>



        


          </div>


          
     </div>
     
</template>


<script>
import axios from "axios";
import Votes from '@/components/we/views/GroupProspectivesProspectiveVotes.vue'


export default{
    
     data(){
          return {    
               group: null,
               prospectivedetails: null,
               panelopen: false,
               votes: null
               
               
          }
     },
     props: ["prospectiveid"],
     components: {
          Votes
     },

     methods: {

          getprospectivesdetails() 
          {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getprospectivesdetails');
               }


               var post = new Object();
               post.task = 'getprospectivesdetails';
               post.prospectiveid = this.prospectiveid;


               // console.log(post);


               axios.post( this.$store.state.config.apiserver + 'we/views/GroupProspectivesProspective.php',post)
               .then(response => {
                    
                    this.prospectivedetails = response.data;
                    // console.log(response.data);
                              
               
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },
          getvotes()
          {

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getvotes');
               }
                    
               var vote = new Object();
               vote.task = 'getvotes';
               vote.prospectiveid = this.prospectiveid;
               vote.groupid = this.$store.state.we.group.id;    
                         

               axios.post( this.$store.state.config.apiserver + 'we/views/GroupProspectivesProspective.php',vote)
               .then(response => {
                                        
               // console.log(response.data);
               this.votes = response.data;
               
               
               
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          },
          vote(vote) {

          
               var obj = new Object();
               obj.userid = this.prospectiveid;
               obj.vote = vote;             
                         
               this.$store.commit('setvote',obj); 
               this.setactiveproperty('panel','WeVote')


          }




     },
     computed:{          
   
 
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.prospectivedetails.userimagename + '.png';

          },

          clubCountry() 
          {
               return this.prospectivedetails['clubCountry' + this.$store.state.current.languageWebsite];

          },
          userid() {
               return this.$store.state.user.id;
          },

          usercanvote() {

               var canvote = true;


               // Heeft de gebruiker al gestemd?


               if (this.votes.some(item => item.voterid === this.store.state.user.id)){

                    canvote = false;

               }


               // // Een gebruiker kan niet op zichzelf stemmen
               
        
               if (this.store.state.user.id == this.prospectiveid) {
                    canvote = false;
               }


               // // prospectives kunnen alleen stemmen als er minder dan 5 leden in de group zitten


               if ((this.$store.state.we.group.userstatus=='prospective') && (this.store.state.group.numberofmembers>4)) {
                    canvote = false;

               }

           

      

               return canvote;

               
          },
          taskchanges(){

               return this.$store.state.active.task;

          }
   
          
     },
     watch: {

          taskchanges(){

               if (this.$store.state.active.task=='refreshvotes'){


                    // Als er net gestemd is voor deze prospective, de votes opnieuw ophalen

                    if (this.$store.state.active.taskitemid == this.prospectiveid){

                         this.getvotes();
                         this.setactiveproperty('task',null);

                    }

                 
               
               }

              

          }

     },
  

     
     mounted() {

        
          this.getprospectivesdetails();
          this.getvotes();



     }
}
</script>

<style scoped>






</style>
