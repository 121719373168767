<template>


     <transition name="slideup">

          <div v-if="this.store.state.active.panel==panelname" class="panel">

               <div v-if="this.store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

      
               <form @submit="postdata">

                    <div class="row panel_header">
                         <div class="col col-10 middle">
                              <h1>{{translate.POSTCREATE_PLAATSBERICHT}}</h1>
                         </div>    

                         <div class="col-2 middle-right">
                              <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw"></a>
                         </div> 

                    </div>     


                    <div v-if="showviews">

                         <div class="row panel_menu">
                                   
                                   <a v-on:click="viewID = '1'" :class="{'active': viewID=='1'}" class="panel_menu_item">Bericht</a>
                                   <a v-on:click="viewID = '2'" :class="{'active': viewID=='2'}" class="panel_menu_item">Facebook</a>
                                   <a v-on:click="viewID = '3'" :class="{'active': viewID=='3'}" class="panel_menu_item">Youtube</a>
                                   <a v-on:click="viewID = '4'" :class="{'active': viewID=='4'}" class="panel_menu_item">Instagram</a>
                              
                         </div>
                    
                    </div> 

                    <div class="panel_content">

                         <div class="row" v-if="viewID==2">

                              <div class="col col-12">
                                   <input   :placeholder="[[translate.POSTCREATE_TITLE]]" v-model="post.title"/>
                              </div>

                              <div class="col col-12">
                                   <input  :class="{bordergroen: !post.facebookcode}" :placeholder="[[translate.POSTCREATE_PLAKJEFACEBOOKLINK]]" v-model="post.facebookcode"/>
                              </div>
                         </div>  

                         <div class="row" v-if="viewID==3">

                              <div class="col col-12">
                                   <input :placeholder="[[translate.POSTCREATE_TITLE]]" v-model="post.title"/>
                              </div>

                              <div class="col col-12">
                                   <input :class="{bordergroen: !post.youtubecode}" :placeholder="[[translate.POSTCREATE_PLAKJEYOUTUBELINK]]" v-model="post.youtubecode"/>
                              </div>
                         </div>  


                         <div class="row" v-if="viewID==4">

                              <div class="col col-12">
                                   <input   :placeholder="[[translate.POSTCREATE_TITLE]]" v-model="post.title"/>
                              </div>

                              <div class="col col-12">
                                   <input   :class="{bordergroen: !post.instagramcode}" :placeholder="[[translate.POSTCREATE_PLAKJEINSTAGLINK]]" v-model="post.instagramcode"/>
                              </div>
                         </div>  


                         <div class="row">
                              <div class="col col-12">
                                   <textarea  :class="{bordergroen: !post.text}" :placeholder="[[translate.POSTCREATE_TEKSTSCHRIJVEN]]" v-model="post.text"/>
                              </div>
                         </div>  


                         <div v-if="showcats">

                              <div class="row mb15 ">
                    
                                        <div class="col col-12">     

                                         
                           
                                             <div v-if="postcat">
                                                  <div v-on:click="setactiveproperty('toppanel','PostCreateCats')" class="select">{{this.store.state.active.postcat[this.$store.state.active.languagewebsite]}}</div> 
                                             </div>
                                             <div v-else>
                                                  <div v-on:click="setactiveproperty('toppanel','PostCreateCats')" class="select">{{translate.POSTCREATE_ALGEMEEN}}</div> 
                                             </div>     
                                             
                              
                                        </div>  

                              </div>
                         
                         </div>


 
                          <div class="row">


                              <div class="col col-10 mb10">
                         
                                   <div v-on:click="setactiveproperty('toppanel','PostCreateLanguage')" class="select">{{postlanguage}}</div> 

                         
                              </div>

                              <div class="col col-2 pt5">
                                   <i v-on:click="resetlanguage()" class="bi bi-arrow-counterclockwise tekstblauw"></i>
                              </div>    

                         </div>


                          <div v-if="showlocations">

                              <div class="row mb15">

                                   <div class="col col-12"> 
                                         <div v-on:click="setactiveproperty('toppanel','PostCreateLocation')" class="select">{{this.$store.state.active.postlocation[this.$store.state.active.languagewebsite]}}</div> 
                                   </div>  

                              </div>   

                         </div>       

                         <div v-if="showin">

                             
                              
                              <div v-if="!isadmin"> 
                                   <div class="row">
                                        <div class="col col-12">
                                             <h2>{{translate.POSTCREATE_WIEMAG}}</h2>
                                        </div>
                                   </div>  


                                   <div class="row mb10 ">
                                        <div class="col col-12">                    
                                             <Toggle id="id1" v-model="showinclub" /> {{translate.POSTCREATE_IEDEREENVAN}} {{userClub.name_long}}
                                        </div>
                                   </div>  


                                   <div class="row mb10">
                                        <div class="col col-12">
                                             <Toggle id="id2" v-model="showinregion" /> {{translate.POSTCREATE_IEDEREENIN}} {{userClub.region}}
                                        </div>
                                   </div>  

                                   

                                   <div class="row mb10">
                                        <div class="col col-12">
                                             <Toggle id="id3" v-model="showincountry" /> {{translate.POSTCREATE_IEDEREENIN}} {{userClubCountry}}
                                        </div>
                                   </div>  


                              
                                   <div class="row mb10">
                                        <div class="col col-12">
                                             <Toggle id="id4" v-model="showincontinent" /> {{translate.POSTCREATE_IEDEREENIN}} {{userClubContinent}}
                                        </div>
                                   </div>  

                                   <div class="row mb10">
                                        <div class="col col-12">
                                             <Toggle id="id5" v-model="showinworld" /> {{translate.POSTCREATE_IEDEREENIN}} {{translate.POSTCREATE_DEWERELD}}
                                        </div>
                                   </div>  
                              </div>      

                              <div v-if="isadmin">
                                   <div class="row mb10">
                                        <div class="col col-12">
                                             Admin plaatsen hun bericht overal.
                                        </div>
                                   </div>                               
                                   
                              </div>

                         </div>
                              
                         <div class="row">
                              <div class="col col-12">

                          

                                   <div v-if="postok">
                                         <button class="button floatright" type="submit"> {{translate.POSTCREATE_BERICHTPLAATSEN}}</button>
                                   </div>
                                   <div v-else>
                                        <a class="button floatright grijs" >{{translate.POSTCREATE_BERICHTPLAATSEN}}</a>
                                   </div>     


                              </div>
                         </div>

                         

                    </div>       
               
               

               </form>
          

          
          </div>

      </transition>     
</template>

<script>

//https://github.com/vueform/toggle

import axios from "axios";
import Toggle from '@vueform/toggle'


export default
     {

  
     data()
     {
          return{

               post:{
               text:null        
               },             
     
               locationID: 1,
               postcatID: 0,
               showinclub: true,
               showinregion: true,
               showincountry: true,
               showincontinent: true,
               showinworld: false,
               viewID: '1',
               panelname: 'PostCreate'


          }
     },
     components:{
    
          Toggle

     },
     computed:
     {
          userClub() 
          {
               return this.$store.state.user.club;
          },
          postcat(){

               return this.store.state.active.postcat[this.store.state.active.languagewebsite];

          },
   
       
          postlanguage(){

               return this.$store.state.active.postlanguage.name;

               
          },


          userClubCountry() {
               return this.$store.state.user.club['country_' + this.$store.state.active.languagewebsite];
          },

          userClubContinent() {
               return this.$store.state.user.club['continent_' + this.$store.state.active.languagewebsite];
          },

          section() {
               return this.$route.name;
          },
          isadmin() {

               if (this.$store.state.user.userrole=="2") {
                    return true
               } else
               {
                    return false                    
               }
          },
          showviews(){

               var show = true;

               if (this.section == 'We') {
                    show = false;
               }

               if (this.section == 'Games') {
                    show = false;
               }


               return show;

          },
          showcats(){

               // Categorieen

               var show = true;

               if (this.section == 'We') {
                    show = false;
               }

               if (this.section == 'Games') {
                    show = false;
               }


               return show;

          },
          showlocations() {

               // Zaal of veld
               
               var show = true;

               if (this.section == 'We') {
                    show = false;
               }

               if (this.section == 'Games') {
                    show = false;
               }


               return show;

          },

          showin() {

               // Iedereen van club enz

               var show = true;

               if (this.section == 'We') {
                    show = false;
               }

               if (this.section == 'Games') {
                    show = false;
               }


               return show;
          },
          thispost() {
               return '';
          },
          postok() {

               var ok = true;
               
               if (!this.post.text)
               {
                    ok =  false;
               } 

               if (this.viewID==2) {

                    if (!this.post.facebookcode)
                    {                                   
                 
                      ok =  false;
                    } 

               }


               if (this.viewID==3) {

                    if (!this.post.youtubecode)
                    {                                
                         ok =  false;
                    } 

               }


               if (this.viewID==4) {

                    if (!this.post.instagramcode)
                    {                                
                         ok =  false;
                    } 

               }

               
               return ok;

          },



     },
   
     methods:
     {

             
          resetlanguage(){

               this.setactiveproperty('postlanguage',this.$store.state.user.languagewrite);
 
          },

          
          
          postdata(e) {

               e.preventDefault();

               if (this.$store.state.config.debug==1) 
               {
               console.log('createpost');
               }

               this.post.clubid = null;
               this.post.regionid = null;
               this.post.countryid = null;
               this.post.continentid = null;
               this.post.worldid = null;

               this.post.task = "createpost";
               this.post.state = 1;    
               
               this.post.languageid = this.$store.state.active.postlanguage.id;
               this.post.hdaccesstoken = this.$store.state.user.hdaccesstoken;
               this.post.viewid = this.viewID;   
               this.post.locationid = this.$store.state.active.postlocation.id; 
               this.post.postcatid = this.$store.state.active.postcat.id;
        

               if (this.$route.name=="Posts") {
               this.post.section = 'posts';               
               }

               if (this.$route.name=="Games") {
               this.post.section = 'games';
               this.post.itemid = this.$store.state.active.gamesvideoid;
               }


               if (this.$route.name=="We") {
               this.post.section = 'we';
               this.post.itemid = this.$store.state.we.group.id
               }

           

               if (this.isadmin === false) 
               {
        
                    

                    if (this.showinclub===true) 
                    {
                         

                         this.post.clubid = this.$store.state.user.club.id;
                    }

                    if (this.showinregion==true) 
                    {
                         
                         this.post.regionid = this.$store.state.user.club.regionid;
                        
                    }

                    if (this.showincountry===true) 
                    {
                         this.post.countryid = this.$store.state.user.club.countryid;
                    }

                    if (this.showincontinent===true) 
                    {
                         this.post.continentid = this.$store.state.user.club.continentid;
                    }

                    if (this.showinworld===true) 
                    {
                         this.post.worldid = 1;
                    }

               }

               // Admins plaatsen berichten in de huidige continent,country,region of club

               if (this.isadmin === true) 
               {
                    var scope = this.$route.params.scope; 


                    if (scope=='club') {
                         this.post.clubid = this.$store.state.current.club.id;
                    }

                    if (scope=='region') {
                         this.post.regionid = this.$store.state.current.region.id;
                    }

                    if (scope=='country') {
                         this.post.countryid = this.$store.state.current.country.id;
                    }

                    if (scope=='continent') {
                         this.post.continentid = this.$store.state.current.continent.id;
                    }

                    if (scope=='world') {
                         this.post.worldid = 1;
                    }
               
                    

               }     



               // console.log(this.post);

               axios.post(this.$store.state.config.apiserver + 'posts/panels/PostCreate.php',this.post)
               .then(response => 
               {           
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log('Posted id: ' + response.data);
                    }                                      
                    
                    this.post.text = '';                    
                    this.post.facebookcode = '';
                    this.post.youtubecode = '';
                    this.post.instagramcode = '';
                    this.post.title = '';

          
                    this.$store.commit('deletepostsids',''); 
                    this.$store.dispatch('fetchpostids'); 
                    this.setactiveproperty('panel',null);
                 
         

               })
               .catch(error => 
               {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });


          },
          

     },
 


  
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

