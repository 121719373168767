<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-10 middle">
                         <h1>{{translate.USERPROFILE_LOCATIONS}}</h1>
                    </div>    
                    <div class="col-2 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="location in this.$store.state.static.locations" :key="location.id"> 

                         <a v-on:click="setlocation(location)" class="button ghost mb10">{{location[this.$store.state.active.languagewebsite]}}</a>


                    </div>

          
               </div>


 


          </div>

     </transition>
  
</template>

<script>


import axios from "axios";


export default {
     
     
     data(){
          return {   
               
               panelname: 'UserProfilelocations',
           
          }
     },
 


     methods:{

        

          setlocation(location){
                    
               if (this.$store.state.config.debug==1) 
               {
               console.log('setuserlocationid');
               }    

               var post = new Object();
               post.task = 'setuserlocationid';
               post.locationid =  location.id;
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
               .then(response => 
               {                   
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }  

                    this.setactiveproperty('toppanel','');

                    this.$store.dispatch('fetchuser');  

                    

                    
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

             
          }
          

     },


 

  
}
</script>

 
<style scoped>

</style>
