<template>

     <div v-if="this.$store.state.active.panel || this.$store.state.active.toppanel " class="overlay open" v-on:click="setactiveproperty('panel','')">
   

     </div>

     <div v-else class="overlay">


     </div>
  
</template>

<script>



export default {

     methods:{

      
     },
 

     computed: 
     {




     }
  
  
}
</script>

 
<style scoped>

.overlay{
position: fixed;
top: 0;
right: 0;
left: 0;
height: 0px;
background-color: rgba(240,240,240);
cursor: pointer;
transition: opacity 5s;  
opacity: 0;
z-index: 15;
}

.overlay.open {
height: auto;
bottom: 0;
opacity: 0.7;
}

</style>
