<template> 

 
    
     <div class="subsubmenu" >   

        
               <div v-if="currentClub">

                    <router-link  :to="{name: 'Clubs', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'clubinfo',sub: 'info', subsub: 'info' }}"><div class="subsubmenu_menu_item">{{translate.HEADER_INFO}}</div></router-link>    
                    <router-link  v-if="currentClub.facebookurl" :to="{name: 'Clubs', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'clubinfo',sub: 'info', subsub: 'facebook'}}"><div class="subsubmenu_menu_item">{{ translate.HEADER_FACEBOOK }}</div></router-link>           
                    <router-link  v-if="currentClub.twitterurl" :to="{name: 'Clubs', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'clubinfo',sub: 'info', subsub: 'twitter' }}"><div class="subsubmenu_menu_item">{{ translate.HEADER_TWITTER }}</div></router-link>                     
                    <router-link  v-if="currentClub.youtubechannel" :to="{name: 'Clubs', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'clubinfo',sub: 'info', subsub: 'youtube' }}"><div class="subsubmenu_menu_item">{{translate.HEADER_YOUTUBE}}</div></router-link>                                  
                    <router-link  v-if="canseeclubmembers" :to="{name: 'Clubs', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'clubinfo',sub: 'info', subsub: 'members' }}"><div class="subsubmenu_menu_item">{{ translate.HEADER_LEDEN }}</div></router-link>                     

                   
               </div>




     </div>     
     
          
    
     
</template>


<script>


export default{
     name:"Location",
     data(){
          return {    
                              
          }
     },
     methods: {

          setAltImg(event) {
               event.target.src = this.$store.state.config.logosurl + 'nologo.png';
          },

          

     },
     computed:{          
   
        
          currentClub() {
               return this.$store.state.current.club
          },
          
          scope() {
               return this.$route.params.scope;               
          },
          clubLogoUrl() {

               if (this.$store.state.current.club.id){
                    return this.$store.state.config.logosurl + this.$store.state.current.club.id + '.png'
               } else {
                    return null
               }
               

          },
  
          routecontinent() {
               return this.$route.params.continent
          },
          routecountry(){
               return  this.$route.params.country
          },
          routeregion(){
               return  this.$route.params.region
          },
          routeclub(){
               return  this.$route.params.club
          },
          params() {
               return  this.$route.params
          },
          canseeclubmembers() {

               // Alleen clubleden mogen de leden zien

               if (this.$store.state.user){

                    if (this.$store.state.user.clubid == this.$store.state.current.club.id ) {                    
                    return true
               } else 
               {
                    return false
               }


               } else {

                    return false;
               }
               

               
          },
   
     },

     
}
</script>


