<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Toppanel | {{panelname}}</div>


               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.POSTFILTERLOCATION_KIES}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content" >

                    

                    <div v-for="location in this.$store.state.static.locations" :key="location.id">  

                         <div v-if="location.id==this.$store.state.active.filterlocationid">
                              <a class="button mb15" v-on:click="setlocation(location)">{{location[this.$store.state.active.languagewebsite]}}</a>   
                              
                         </div>

                         <div v-else>
                              <a class="button ghost mb15" v-on:click="setlocation(location)">{{location[this.$store.state.active.languagewebsite]}}</a>   
                         </div>


                                          
                    </div>

          
               </div>


          

          </div>
     </transition>     
  
</template>

<script>



export default {

 
     data() {
          return{
               panelname: 'PostsFilterLocations'
         
          
               
          }
     },

     methods:{

          setlocation(id) {

               this.setactiveproperty('filterlocation',id);
               this.setactiveproperty('toppanel','');
          }
          

     },

    

  
}
</script>

 
<style scoped>

</style>
