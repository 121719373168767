<template>

     <transition name="slideup">
        

          <div v-if="this.$store.state.active.toppanel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.USERPROFILE_OPMIJNCLUB}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="useractivity in this.$store.state.static.useractivities" :key="useractivity"> 
                         
     
                         <a v-on:click="setuseractivity(useractivity)" class="button ghost mb10">{{useractivity[this.$store.state.active.languagewebsite]}}</a>
                        

                    </div>

          
               </div>


 


          </div>

     </transition>
  
</template>

<script>


import axios from "axios";


export default {

 
     data() {
          return{
               
               panelname: 'UserProfileActivities'
          
               
          }
     },

     components: {


     },
     methods:{

         

          setuseractivity(useractivity){



               if (this.$store.state.config.debug==1) 
               {
               console.log('setuseractivity');
               }    

               var post = new Object();
               post.task = 'setuseractivity';
               post.useractivityid =  useractivity.id
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
               .then(response => 
               {                   
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }  
                    this.$store.dispatch('fetchuser');  
                    this.setactiveproperty('toppanel','');

                    
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });



          }
          

     },

    computed: 
    {


  



    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
