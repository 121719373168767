<template>
	<div v-if="searchstring" id="searchcontainer">
		<div v-if="searchresults.length > 0" class="search">
			<div v-for="club in searchresults" :key="club">
				<div v-on:click="gotoclub(club.id)" class="club">
					<span class="dik">{{ club.name_long }}</span>
					<span class="tekstgrijs">&bull; {{ club.place }} &bull; {{ club[this.$store.state.active.languagewebsite] }}</span>
				</div>
			</div>
		</div>

		<div v-if="searchresults.length < 1" class="search">
			<div v-if="loggedin">
				<a class="button ghost" v-on:click="setactiveproperty('panel', 'AddClub')">{{ translate.SEARCH_ADDCLUB }}</a>
			</div>

			<div v-if="!loggedin">
				<a class="button ghost" v-on:click="setactiveproperty('panel', 'Login')">{{ translate.SEARCH_ADDCLUB }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			searchresults: [],
		};
	},
	methods: {
		gotoclub(clubid) {
			if (this.$store.state.config.debug == 1) {
				console.log("getclub");
			}

			this.setactiveproperty("screen", null);

			var post = new Object();
			post.task = "getclub";
			post.clubid = clubid;

			axios.post(this.$store.state.config.apiserver + "common/Search.php", post)
				.then((response) => {
					var club = response.data;
					// console.log(response.data);

					this.searchresults = [];

					this.setactiveproperty("searchstring", "");

					if (this.$store.state.route.section == "clubs") {
						this.$router.push({name: "Clubs", params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: "clubinfo", sub: "info", subsub: "info"}});
					}

					if (this.$store.state.route.section == "posts") {
						this.$router.push({name: "Posts", params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: "club", sub: "posts", subsub: "all"}});
					}

					if (this.$store.state.route.section == "home") {
						this.$router.push({name: "Posts", params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: "club", sub: "posts", subsub: "all"}});
					}

					if (this.$store.state.route.section == "games") {
						// <router-link  v-if="this.$store.state.games.competition" :to="{name: 'Games', params:{ competition: this.$store.state.games.competition.slug, game: 'game-' + this.$store.state.games.competition.latestgame, sub3: 'play'}}">

						this.$router.push({name: "Posts", params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: "club", sub: "posts", subsub: "all"}});
					}

					// http://localhost:8080/posts/europe/the-netherlands/midden-nederland/shinty/club/posts/all
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error("There was an error!", error);
				});
		},
	},

	computed: {
		searchstring() {
			return this.$store.state.active.searchstring;
		},
	},
	watch: {
		searchstring() {
			if (this.$store.state.active.searchstring.length > 0) {
				if (this.$store.state.config.debug == 1) {
					console.log("search");
				}

				var post = new Object();
				post.task = "searchclubs";
				post.searchstring = this.searchstring;

				// console.log(this.searchstring);

				axios.post(this.$store.state.config.apiserver + "common/Search.php", post)
					.then((response) => {
						// console.log(response.data);

						this.searchresults = response.data;
					})
					.catch((error) => {
						this.errorMessage = error.message;
						console.error("There was an error!", error);
					});
			} else {
				this.searchresults = [];
			}
		},
	},
};
</script>
<style scoped>
.search {
	position: fixed;
	top: 125px;
	left: 0px;
	right: 0px;
	height: auto;
	background: #fff;
	padding: 15px;
	overflow: hidden;
}

.search .club {
	padding: 10px 0px;
	transition: all 0.4s;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}

.search .club:hover {
	background: #f8f8f8;
}

#searchcontainer {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 150px;
	z-index: 10;
}

/* SM */
@media (min-width: 576px) {
}

/* MD */
@media (min-width: 768px) {
	.middle {
		width: calc(100vw - 320px);
		max-width: 1000px;
		margin: 0 0;
	}
}

/* LG */
@media (min-width: 992px) {
	#searchcontainer {
		position: fixed;
		left: 0px;
		right: 0px;
		top: 80px;
		z-index: 10;
	}

	#searchcontainer .search {
		margin: 0 auto;
		width: calc(100vw - 600px);
		max-width: 800px;
	}
}

/* XL */
@media (min-width: 1200px) {
}
</style>
