<template>

     <div id="width" class="bgwit borderradiusgroot">
          <div v-if="videos">  

               <div v-for="video in videos" :key="video"> 

                    <div class="p10 dik">{{video.title}}</div>

                    <iframe :width="width" :height="height" :src="getcode(video)"   :srcdoc="getimage(video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                    
                 
                              
               </div>
          </div>

     </div>
</template>

<script>
import axios from "axios";

export default {

  

     data() {
          return{

               videos: null
          
          }
     },
     methods: {

          getcode(video){

               return 'https://www.youtube.com/embed/' + video.link.split('=')[1]


          },
          getimage(video){

               var videolink = 'https://www.youtube.com/embed/' + video.link.split('=')[1] + '?autoplay=1';
               
               var code = "<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=" + videolink + "><img src=" + video.thumbnail + "><span>▶</span></a>";
               return code

          },


          getyoutubeids() {

               // via rss2json haal ik via de rss pagina van youtube de videos op.
             

               
               if (this.$store.state.current.club.youtubechannel) 
               {
                    
     
                    var channel = this.$store.state.current.club.youtubechannel;
                    

                    if (channel.includes('channel')) {
              

                         id  = this.$store.state.current.club.youtubechannel.split('/')[4];                   
                    }

                    if (channel.includes('xml')) {

                         var id  = this.$store.state.current.club.youtubechannel.split('=')[1];                       
                    }
                   

                    var url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id=' + id + '&api_key=ibvcpvhczajcipzvzrbavlesupriqyymln6kugii';                



                    axios.get(url)
                    .then(response => {               
                                             
                         this.videos = response.data.items;
                         //console.log(response.data.items);
                         

                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    });


               }

       




          }



     },
     computed:
     {          
   
          translate() 
          {       
               return this.$store.state.translate;        
          },
          club() 
          {             
               return this.$store.state.current.club;
          },

          width() {
               return document.getElementById("width").offsetWidth;
          },
          height() {
               return (document.getElementById("width").offsetWidth * 0.56)
          }

     },
     mounted() {

          this.getyoutubeids();

     },
     beforeCreate() {

      

          // var externalScript = document.createElement('script')
          // externalScript.setAttribute('src', 'https://apis.google.com/js/platform.js')
          // externalScript.setAttribute('crossorigin', 'anonymous')       
          // document.head.appendChild(externalScript)


     }




}
</script>
