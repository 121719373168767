<template>

     <div v-if="member" class="view_subview" > 

          <div class="row">

               <div class="col col-12 verticalcenter ">          

                    <img class="profilepicture" :src="profilepicture"/>

                    <div class="tekstgrijs klein">
                         <i class="bi bi-shield-shaded"></i><span class="dik tekstzwart">{{member.userFirstname}} {{member.userLastname}}</span> &bull;
                          {{member.clubNamelong}} &bull; {{clubCountry}}  
                    </div>

               </div>

        

          </div>

          

          
     </div>
     
</template>


<script>



export default{



    
     data(){
          return {    

               
               
          }
     },
     props: ["member"],
     components: {
         
     },
     computed:{          
   
          translate() 
          {       
               return this.$store.state.translate;        
          },
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.member.userimagename + '.png';

          },
          clubCountry() 
          {
               return this.member['clubCountry' + this.$store.state.current.languageWebsite];

          },
          group(){
               return this.$store.state.current.group;
          },
    
          
     },
     watch: {


     },


     methods: {

  


 



     },
     created() {





     }
}
</script>

<style scoped>






</style>
