<template>

     <div v-if="user" class="panel_section" >


          <div class="row">


               <div class="links">
                    <img class="profilepicture"  :src="profilepicture" />

               </div>

               <div class="midden tekstgrijs klein">

                    <span class="dik tekstzwart">{{user.userFirstname}} {{user.userLastname}}</span> 
                    &bull; {{user.clubNamelong}} 
                    &bull; {{user.clubPlace}} 
                    {{clubcountry}} &bull; {{clubcontinent}}

               </div>

               <div class="rechts">


               </div>


               


      
               <!-- <i class="bi bi-shield-shaded mr5"></i>   -->
              

          </div>     
                    
          

          
          
     </div>
     
</template>


<script>
import axios from "axios";

export default{

     data(){
          return {    
               user: null           
          }
     },
     props: ["userid"],
     computed:{          
   
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.user.userimagename + '.png';

          },
          clubcountry() {
               return this.user['clubCountry' + this.$store.state.active.languagewebsite];
          },
          clubcontinent() {
               return this.user['clubContinent' + this.$store.state.active.languagewebsite];
          },

          
     },


     methods: {

   

          fetchuser()  
          {
             

               if (this.$store.state.config.debug==1) 
               {
                    console.log('fetchusers');
               }    
               
      
               var post = new Object();
               post.task = 'fetchuser';
               post.userid =  this.userid;  


               axios.post( this.$store.state.config.apiserver + 'users/panels/BookmarksUsers.php', post)
               .then(response => {
                    this.user = response.data;
                    // console.log(response.data);
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },


     },
     mounted() {
          this.fetchuser();
     }
}
</script>

