<template>      


     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>


               <div class="row panel_header">
                    <div class="col col-10 middle">
                         <h1>{{translate.CREATECOMMENT_KOP}}</h1>
                    </div>    
                    <div class="col-2 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div class="row">
                         
                         <div class="col col-12">

                              <form @submit="postdata">
                                   
                                   <div class="row">
                                        <div class="col col-12">
                                             <textarea class="textarea" v-model="comment.text" :placeholder="[[translate.COMMENTCREATE_SCHRIJFEENOPMERKING]]" />
                                        </div>

                                         <div class="col col-12 alignright">      

                                             <div v-if="postok">
                                                  <button class="button floatright" type="submit"> {{translate.CREATECOMMENT_PLAATSEN}}</button>
                                             </div>
                                             <div v-else>
                                                  <a class="button floatright grijs" > {{translate.CREATECOMMENT_PLAATSEN}}</a>
                                             </div>  

                                            
                                        </div>
                                   </div>

                              </form>

                         </div>

                    </div>  

               </div>     

          </div>

     </transition> 

</template>

<script>

import axios from "axios";



export default {

     name: 'CommentCreate',

     data() {
          return{
               comment:
               {
                    text:null        
               },
               panelname: 'CreateComment'

               
          }
     },

     computed: 
     {    
          postok() {

               if (this.comment.text) 
               {
                    return true;
               } else {

                    return false;
               }
               

          },

     

     },

     methods:{

          postdata(e) {

          e.preventDefault();

          if (this.$store.state.config.debug==1) 
          {
          console.log('createcomment');
          }

          var post = new Object();
          post.task = "createcomment";
          post.state = 1;   
          post.languageid = this.$store.state.user.languagewriteid;
          post.parenttype = this.$store.state.active.item.type;
          post.parentid = this.$store.state.active.item.id;
          post.hdaccesstoken = this.$store.state.user.hdaccesstoken;
          post.text = this.comment.text;

          // console.log(post);
        
          axios.post(this.$store.state.config.apiserver + 'interactions/panels/CreateComment.php',post)
          .then(response => 
          {           
               console.log(response.data);                 
               this.comment.text = '';             
   
               this.setactiveproperty('panel','ShowComments');
               this.setactiveproperty('task','getcomments');

  
    
          })
          .catch(error => 
          {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
          });


         },


    }


  
}
</script>

 

