<template>

     <transition name="slideup">

          <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>
              

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.FILTERAREAREA_KOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">


                         <div class="row mb5">
                              <div class="col-10 middle">
                              <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaContinents')" class="select">{{continent.name}}</div>
                              </div>    

                              <div class="col-2 middle-right">               
                                   <a class="bi bi-arrow-right-circle-fill tekstblauw"  v-on:click="gotocontinent()"></a>
                              </div>

                         </div>    

               

                         <div class="row mb5" v-if="continent.id>1">
                              <div class="col-10 middle">           
                              <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaCountries')" class="select">{{country.name}}</div>
                              </div> 

                              <div class="col-2 middle-right">         
                              <a  v-if="country.id>0" v-on:click="gotocountry()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                              </div>
                         </div>     


                         <div class="row mb5" v-if="country.id>0" >

                         <div class="col-10"  >

                         <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaRegions')" class="select">{{region.name}}</div>

                         </div> 

                         <div class="col-2 middle-right">             

                         <a v-if="region.id>0" v-on:click="gotoregion()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                         </div>

                         </div>     



                         <div class="row clubs" v-if="region.id>0">    

                         <div class="col-10 middle">    

                         <div v-on:click="setactiveproperty('toppanel','FilterAreaAreaClubs')" class="select"> {{club.name_long}}</div>


                         </div> 
                         <div class="col-2 middle-right" >

                         <a v-if="club.id>0" v-on:click="gotoclub()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
                         </div>

                    </div>

          
               </div>


  

          </div>

     </transition>
  
</template>


<script>

import axios from "axios";




export default{
     data() {
          return{
               panelname: 'FilterAreaArea',
     
          }
     },

  
     methods: {

          gotocontinent() {


               if (this.$store.state.active.filterareacontinent.id == 0 ) 
               {
                    // We zijn in de wereld 
                    this.$router.push({ name: 'Posts', params:{ continent: '-', country: '-',region: '-',club: '-',scope: 'world',sub: 'posts', subsub: 'all' } })
                    
                    this.setactiveproperty('panel','');
                    this.setactiveproperty('screen',null);
          


               } else
               {
                    var continentslug = this.$store.state.active.filterareacontinent.slug;      
                    
                    this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: '-',region: '-',club: '-',scope: 'continent',sub: 'posts', subsub: 'all' } })
                    this.setactiveproperty('panel','');
                    this.setactiveproperty('screen',null);


               }
          },
          gotocountry() {

               var continentslug = this.$store.state.active.filterareacontinent.slug;  
               var countryslug = this.$store.state.active.filterareacountry.slug;                                         
         
               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: '-',club: '-',scope: 'country',sub: 'posts', subsub: 'all' } })
               this.setactiveproperty('panel',''); 
               this.setactiveproperty('screen',null);

                           
          },
          gotoregion() {
               var continentslug = this.$store.state.active.filterareacontinent.slug;  
               var countryslug = this.$store.state.active.filterareacountry.slug;  
               var regionslug = this.$store.state.active.filterarearegion.slug;

               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: regionslug,club: '-',scope: 'region',sub: 'posts', subsub: 'all' } })

               this.setactiveproperty('toppanel','');
               this.setactiveproperty('screen',null);
 
            
          },
          gotoclub() {

               var continentslug = this.$store.state.active.filterareacontinent.slug;  
               var countryslug = this.$store.state.active.filterareacountry.slug;  
               var regionslug = this.$store.state.active.filterarearegion.slug;  
               var clubslug = this.$store.state.active.filterareaclub.slug;               
         
               this.$router.push({ name: 'Posts', params:{ continent: continentslug, country: countryslug,region: regionslug,club: clubslug,scope: 'club',sub: 'posts', subsub: 'all' } })

               this.setactiveproperty('panel',''); 
               this.setactiveproperty('screen',null);

               
          }

     },
     computed: 
     {
          continent(){

               if (this.$store.state.active.filterareacontinent) {
                    return this.$store.state.active.filterareacontinent;
               } else
               {
                   
                    const continent = {};
                    continent.name = this.translate.FILTER_KIESCONTINENT;
                    continent.id = 0;
                    return continent
               }
   
          },     

     
          country(){
               
               if (this.$store.state.active.filterareacountry) {
               
          
                    const country = this.$store.state.active.filterareacountry;
                    country.name = this.$store.state.active.filterareacountry[this.$store.state.active.languagewebsite];
                    return country;

               } else
               {
                    const country = {};
                    country.id = 0;
                    country.name = this.translate.FILTER_KIESLAND;
                    return country;
               }

               
          },
     
          region() {
               
               if (this.$store.state.active.filterarearegion) {

                    return this.$store.state.active.filterarearegion

               } else
               {

                 
                    const region = {};
                    region.name = this.translate.FILTER_KIESREGIO;
                    region.id = 0;
                    return region;
               }

               
               

          },
    

          club() {

               if (this.$store.state.active.filterareaclub) {

                    return this.$store.state.active.filterareaclub;

               } else
               {
                    const club = {};
                    club.id = 0;
                    club.name_long = this.translate.FILTER_KIESCLUB;
                    return club;
               }

               

          },
          section(){
               return this.$route.name;

          }

         



     }, 
 
     Mounted() {

          // Continenten ophalen

          var post = new Object();
          post.task = 'getcontinents';
  

          axios.post( this.$apiserver + 'clubs/panels/SearchFilter.php', post)
          .then(response => {               
               //console.log(response.data);
               this.continents = response.data;




          })
          .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          });
          



     }     



}
</script>

<style scoped>
     
#SearchFilter{
position: absolute;
top: 5px;
left: 5px;
z-index: 2;
right: 5px;
}

/* Mijn club staat er niet bij */
#SearchFilter .clubs .multiselect-options .multiselect-option:nth-last-child(3) {
     font-weight: 700;
}

  


#SearchFilter .multiselect-input {
     background: #fff;
}
     



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  

     #SearchFilter{
 
     width: 400px;
     right: auto;
     }
 
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    
}


</style>
