export default {


     setgroup(state,group){

          state.we.group = group
          
     },

     setweproperty(state,payload){

          console.log(payload);

          state.we[payload.property] = payload.value;
          
     },

     setvote(state,payload){         

          state.we.vote = payload;
          
     },
   
   

   



}