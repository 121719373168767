<template>

 
     <Filter/>
     <ClubsHeader/>

     <div v-if="$route.params.sub=='info'" >

          <Clubinfo v-if="$route.params.subsub=='info'" />
          <Clubyoutube v-if="$route.params.subsub=='youtube'" />
          <Clubfacebook v-if="$route.params.subsub=='facebook'" />         
          <Clubtwitter v-if="$route.params.subsub=='twitter'" />
          <Clubmembers v-if="$route.params.subsub=='members'" />
     
     </div>


 
     
</template>


<script>

import Filter from '@/components/common/Filter.vue';
import ClubsHeader from '@/components/clubs/ClubsHeader.vue';
import Clubinfo from '@/components/clubs/views/Clubinfo.vue'
import Clubyoutube from '@/components/clubs/views/Clubyoutube.vue'
import Clubfacebook from '@/components/clubs/views/Clubfacebook.vue'
import Clubtwitter from '@/components/clubs/views/Clubtwitter.vue'
import Clubmembers from '@/components/clubs/views/Clubmembers.vue'







export default{
     name:"View",
     data(){
          return {    
                              
          }
     },
     components: {
          Clubinfo,
          Clubyoutube,
          Clubfacebook,
          Clubtwitter,
          Clubmembers,
          Filter,
          ClubsHeader
        
     },
     computed:{          
   
          translate() 
          {       
               return this.$store.state.translate;        
          }

          

          
     },
     watch: {



     },


     methods: {




     




     }
}
</script>

<style scoped>






</style>
