<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.panel == panelname" class="panel">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>

			<div class="row panel_header">
				<div class="col col-6 middle">
					<h1>{{ translate.LOGIN_KOP }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>

			<div class="row panel_content horizontalverticalcenter">
				{{ translate.LOGIN_TEKST }}
				<br />
				<br />
				<a :href="login + '&provider=facebook'" class="button">{{ translate.LOGIN_LOGINWITHFACEBOOK }}</a>

				<br />
				<a :href="login + '&provider=google'" class="button">{{ translate.LOGIN_LOGINWITHGOOGLE }}</a>

				<br />
				<a :href="login + '&provider=twitter'" class="button">Twitter</a>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {
			panelname: "Login",
		};
	},
	computed: {
		translate() {
			return this.$store.state.translate;
		},
		taskShowPanel() {
			if (this.$store.state.task.showPanel == "Login") {
				return true;
			} else {
				return false;
			}
		},
		login() {
			return "https://www.hockeydo.com/auth/login.php?return=" + encodeURIComponent(window.location);
		},

		googlelogin() {
			return "https://www.hockeydo.com/login/google/login.php?return=" + encodeURIComponent(window.location);
		},
	},
	methods: {
		closepanel() {
			this.$store.dispatch("taskShowPanel", null);
		},
	},
};
</script>
