<template>

     <transition name="slideup">

        

          <div v-if="this.$store.state.active.panel==panelname" class="panel">


               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

               <div class="row panel_header middle">
                    <div class="col col-6 middle">
                         <h1>{{translate.USERPROFILE_TAALVANDEWEBSITE}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-for="language in languageswebsite" :key="language">  


                         <span v-if="language.toLowerCase()==languagewebsite">
                              <a v-on:click="setlanguage(language)" class="button mb10">{{language}}</a>
                         </span>
                         <span v-else>
                              <a v-on:click="setlanguage(language)" class="button ghost mb10">{{language}}</a>

                         </span>


                         
                        

                    </div>


                    

          
               </div>



          </div>

     </transition>
  
</template>

<script>

// import axios from "axios";




export default {

 
     data() {
          return{
               
               panelname: 'CommonWebsiteLanguage',
               languageswebsite:  ["en","nl","de","fr","it","pt","es"]
          
               
          }
     },


     methods:{

         
  
          setlanguage(language)
          {

               if (this.$store.state.config.debug==1) 
               {
               console.log('setlanguagewebsite');
               }    

               this.setactiveproperty('languagewebsite',language);
               this.setactiveproperty('panel','');
               

          }
          

     },

    computed: 
    {
     

    },

    watch: 
    {

    }


  
}
</script>
 
<style scoped>

</style>
