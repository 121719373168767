<template>

     <div id="HockeydoInfo" class="view">        


          <h1 v-on:click="togglepanel('state1')">{{translate.HOCKEYDO_INFO_1_KOP}}</h1>
          <div class="pane" :class="state1">
               <div class="tekst">
                    {{translate.HOCKEYDO_INFO_1_TEKST}}
               </div>
         
          </div>

          <h1 v-on:click="togglepanel('state2')">{{translate.HOCKEYDO_INFO_2_KOP}}</h1>
          <div class="pane" :class="state2">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_2_TEKST}}
               </div>
          </div>

          
          <h1 v-on:click="togglepanel('state3')">{{translate.HOCKEYDO_INFO_3_KOP}}</h1>
          <div class="pane" :class="state3">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_3_TEKST}}
               </div>
          </div>


          <h1 v-on:click="togglepanel('state4')">{{translate.HOCKEYDO_INFO_4_KOP}}</h1>
          <div class="pane" :class="state4">
               <div class="tekst">
                     {{translate.HOCKEYDO_INFO_4_TEKST}}
               </div>
         
          </div>

          <h1 v-on:click="togglepanel('state5')">{{translate.HOCKEYDO_INFO_5_KOP}}</h1>
          <div class="pane" :class="state5">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_5_TEKST}}
               </div>
          </div>

          
          <h1 v-on:click="togglepanel('state6')">{{translate.HOCKEYDO_INFO_6_KOP}}</h1>
          <div class="pane" :class="state6">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_6_TEKST}}
               </div>
          </div>


          <h1 v-on:click="togglepanel('state7')">{{translate.HOCKEYDO_INFO_7_KOP}}</h1>
          <div class="pane" :class="state7">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_7_TEKST}}
               </div>
          </div>

          <h1 v-on:click="togglepanel('state8')">{{translate.HOCKEYDO_INFO_8_KOP}}</h1>
          <div class="pane" :class="state8">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_8_TEKST}}
               </div>
          </div>
          <h1 v-on:click="togglepanel('state9')">{{translate.HOCKEYDO_INFO_9_KOP}}</h1>
          <div class="pane" :class="state9">
                  <div class="tekst">
                    {{translate.HOCKEYDO_INFO_9_TEKST}}
               </div>
          </div>
         
      
          
     </div>
     
</template>


<script>
//import axios from "axios";

export default{

     data(){
          return {   
               
               state1: 'open',
               state2: 'closed',
               state3: 'closed',
               state4: 'closed',
               state5: 'closed',
               state6: 'closed',
               state7: 'closed',
               state8: 'closed',
               state9: 'closed',
                              
          }
     },
    
     computed:{          
   
          translate() 
          {       
               var tekst = this.$store.state.translate;

               //console.log(tekst.HOCKEYDO_INFO_4_TEKST);

               return tekst;
          },    


          

          
     },
     methods: {

          togglepanel(id) {

               this.state1 = '';
               this.state2 = '';
               this.state3 = '';
               this.state4 = '';
               this.state5 = '';
               this.state6 = '';
               this.state7 = '';
               this.state8 = '';
               this.state9 = '';


               if ( id =='state1')   {   this.state1 = 'open';  }
               if ( id =='state2') {  this.state2 = 'open'; }
               if ( id =='state3') {  this.state3 = 'open'; }
               if ( id =='state4')   {   this.state4 = 'open';  }
               if ( id =='state5') {  this.state5 = 'open'; }
               if ( id =='state6') {  this.state6 = 'open'; }
               if ( id =='state7')   {   this.state7 = 'open';  }
               if ( id =='state8') {  this.state8 = 'open'; }
               if ( id =='state9') {  this.state9 = 'open'; }


          
          }




     




     }
}
</script>

<style scoped>

#HockeydoInfo h1 {
cursor: pointer; 
border: 1px solid #eee;
border-radius: 3px;
padding: 5px 15px;
}

#HockeydoInfo .pane {
max-height: 0;
overflow: hidden;
transition: all 0.2s;
padding: 0 15px;

}

#HockeydoInfo .pane .tekst {
    margin-bottom: 15px; 
    white-space: pre-line;
}

#HockeydoInfo .pane.open {
max-height: 4000px;
transition: none;
}

</style>

