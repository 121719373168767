<template>
	<div class="mobileheader">
		<div class="item">
			<router-link class="item" :to="{name: 'Hockeydo', params: {view: 'info'}}">
				<img :src="hockeydologokleinsrc" class="logo" />
				<div class="text">{{ translate.MOBILEHEADER_INFO }}</div>
			</router-link>
		</div>

		<div class="item">
			<i v-on:click="openmap()" class="item tekstgroen bi bi-globe"></i>
			<div class="text">{{ translate.MOBILEHEADER_CLUBZOEKER }}</div>
		</div>

		<div class="item" v-if="loggedin">
			<img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl" @error="setAltImg" />
			<div v-if="this.$store.state.current.club" class="text">{{ this.$store.state.current.club.name_short }}</div>
		</div>

		<div class="item" v-if="loggedin">
			<i class="item bi bi-bell-fill tekstgroen" v-on:click="setactiveproperty('panel', 'Notifications')"></i>
			<div class="text">{{ translate.MOBILEHEADER_MELDINGEN }}</div>
		</div>

		<div class="item" v-if="loggedin">
			<i class="item bi bi-bookmark-fill tekstgroen" v-on:click="setactiveproperty('panel', 'Bookmarks')"></i>
			<div class="text">{{ translate.MOBILEHEADER_OPGESLAGEN }}</div>
		</div>

		<div class="item" v-if="loggedin">
			<img class="item profilepicture cursorpointer" v-on:click="setactiveproperty('panel', 'UserProfile')" :src="profilepicturersrc" :title="this.$store.state.user.id" />
			<div class="text">{{ translate.MOBILEHEADER_ACCOUNT }}</div>
		</div>

		<div class="item" v-if="!loggedin">
			<i class="item bi bi-translate tekstgroen" v-on:click="setactiveproperty('panel', 'CommonWebsiteLanguage')"></i>
			<div class="text">{{ translate.MOBILEHEADER_LANGUAGE }}</div>
		</div>

		<div class="item" v-if="!loggedin">
			<div class="item">
				<a v-on:click="setactiveproperty('panel', 'Login')" class="login button">{{ translate.HEADER_LOGIN }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "Header",
	data() {
		return {
			latestcompetition: null,
		};
	},

	methods: {
		openmap() {
			if (this.store.state.active.screen == "Map") {
				this.setactiveproperty("screen", null);
			} else {
				this.setactiveproperty("screen", "Map");
			}
		},

		closesearch() {
			this.searchstring = "";
			this.setactiveproperty("searchstring", "");
		},

		gotoownclub() {
			if (typeof this.$store.state.user.club !== "undefined") {
				var club = this.$store.state.user.club;

				this.$router.push({name: "Posts", params: {continent: club.continent_slug, country: club.country_slug, region: club.region_slug, club: club.slug, scope: "club", sub: "posts", subsub: "all"}});
			}
		},

		getlatestcompetition() {
			var post = new Object();
			post.task = "getlatestcompetition";

			axios.post(this.$store.state.config.apiserver + "common/HeaderTop.php", post)
				.then((response) => {
					this.latestcompetition = response.data;
					// console.log(response.data)     ;
				})
				.catch((error) => {
					this.errorMessage = error.message;
					console.error("There was an error!", error);
				});
		},
	},
	computed: {
		userclub() {
			return this.$store.state.user.club;
		},
		clubLogoUrl() {
			if (this.$store.state.current.club) {
				return this.$store.state.config.logosurl + this.$store.state.current.club.id + ".png";
			} else {
				return null;
			}
		},
		profilepicturersrc() {
			return "https://www.hockeydo.com/users/" + this.$store.state.user.imagename + ".png";
		},
		hockeydologokleinsrc() {
			return this.$store.state.config.logosurl + "logo-klein.png";
		},

		searchstring: {
			get() {
				return this.$store.state.active.searchstring;
			},
			set(value) {
				var obj = new Object();
				obj.property = "searchstring";
				obj.value = value;
				this.$store.commit("setactiveproperty", obj);
			},
		},
	},
};
</script>

<style scoped>
.mobileheader {
	display: flex;
	justify-content: space-between;
	margin-right: 10px;
	margin-left: 10px;
}

.logo {
	height: 35px;
	margin-top: 5px;
	margin-bottom: -3px;
	width: auto;
}

.item {
	text-align: center;
}

i {
	padding: 10px 5px 0px 5px;
	margin: 0 auto;
	font-size: 30px;
}

.mobileheader .profilepicture {
	padding: 5px;
	width: 45px;
	height: 45px;
	margin: 3px auto -6px auto;
	border-radius: 45px;
	border: none;
}

.item .text {
	font-size: 11px;
	width: 100%;
	text-align: center;
	color: #3bb58e;
}

.clublogo {
	height: 45px;
	width: auto;
	padding: 8px 5px 0px 5px;
	margin-bottom: -8px;
}

.login {
	margin-top: 10px;
}
</style>
