<template>
	<transition name="slideup">
		<div v-if="this.$store.state.active.panel == panelname" class="panel autoheight">
			<div v-if="this.$store.state.config.debug == 1" class="debug">Panel | {{ panelname }}</div>
			<div class="row panel_header middle">
				<div class="col col-6 middle">
					<h1>{{ translate.GAMESHEADER_TITLE }}</h1>
				</div>
				<div class="col-6 middle-right">
					<a v-on:click="setactiveproperty('panel', '')" class="bi bi-x tekstblauw floatright"></a>
				</div>
			</div>
			<div class="panel_content">
				<table width="100%">
					<tr>
						<td width="33%" style="vertical-align: top">
							<div v-for="competition in competitions" v-bind:key="competition.id">
								<span v-if="current.competition.id == competition.id">
									<router-link :to="{name: 'Games', params: {competition: competition.slug, game: 'game-' + current.game.game, video: 'video-1', mode: current.mode}}">
										<a class="button mb10">{{ competition["title_" + languagewebsite] }}</a>
									</router-link>
								</span>
								<span v-else>
									<router-link :to="{name: 'Games', params: {competition: competition.slug, game: 'game-' + current.game.game, video: 'video-1', mode: current.mode}}">
										<a class="button ghost mb10">{{ competition["title_" + languagewebsite] }}</a>
									</router-link>
								</span>
							</div>
						</td>

						<td width="33%" style="vertical-align: top">
							<div v-if="games">
								<div v-for="game in games" v-bind:key="game">
									<span v-if="current.game.id == game.id">
										<router-link :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + game.game, video: 'video-1', mode: current.mode}}">
											<a class="button mb10">{{ translate.GAMESHEADER_WEEK }} {{ game.game }}</a>
										</router-link>
									</span>

									<span v-else>
										<router-link :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + game.game, video: 'video-1', mode: current.mode}}">
											<a class="button ghost mb10">{{ translate.GAMESHEADER_WEEK }} {{ game.game }}</a>
										</router-link>
									</span>
								</div>
							</div>
						</td>

						<td width="33%" style="vertical-align: top">
							<div v-if="videos">
								<div v-for="(video, index) in videos" v-bind:key="index">
									<span v-if="current.video.id == video.id">
										<router-link :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + video.nummer, mode: current.mode}}">
											<a class="button mb10">Video {{ video.nummer }}</a>
										</router-link>
									</span>
									<span v-else>
										<router-link :to="{name: 'Games', params: {competition: current.competition.slug, game: 'game-' + current.game.game, video: 'video-' + video.nummer, mode: current.mode}}">
											<a class="button ghost mb10">Video {{ video.nummer }}</a>
										</router-link>
									</span>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
// import axios from "axios";
import {mapState} from "vuex";

export default {
	data() {
		return {
			panelname: "GamesMenu",
		};
	},

	methods: {},

	computed: {
		...mapState(["current", "competitions"]),

		games() {
			var games = this.current.competition.games;

			games.sort((a, b) => a.ordering - b.ordering);

			return games;
		},

		videos() {
			var game = this.current.game;

			const videoFields = ["video_1", "video_2", "video_3", "video_4", "video_5", "video_6", "video_7", "video_8"];
			const filledVideos = videoFields
				.map((field, index) => {
					if (typeof game[field] === "object" && game[field] !== null && Object.keys(game[field]).length > 0) {
						return {
							...game[field],
							nummer: index + 1,
						};
					}
					return null;
				})
				.filter((video) => video !== null);

			return filledVideos;
		},
	},
};
</script>
