<template>

     <div class="view">     

          <h1>{{translate.CLUBMEMBERS_LEDEN}}</h1>   


          <div v-if="members">

               <div v-for="member in members" :key="member.id">            

                    <Member v-bind:member="member"/>
              
               
               </div>

          </div>

          
     </div>
     
</template>


<script>
import axios from "axios";
import Member from '@/components/clubs/views/ClubmembersMember.vue'

export default{
    
     data(){
          return {    
               members: null            
          }
     },
     components:{
          Member
     },
     computed:{          
   
          translate() 
          {       
               return this.$store.state.translate;        
          }    

          
     },
     watch: {



     },


     methods: {

          getmembers() 
          {
               var post = new Object();
               post.task = 'getmembers';
               post.clubslug = this.$route.params.club;

               //console.log(post);
               

               axios.post( this.$store.state.config.apiserver + 'clubs/views/clubmembers.php',post)
               .then(response => {               
                    
                    // console.log(response.data);
                    this.members = response.data;

               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          }


     },
     mounted() {

          this.getmembers();

     }

}
</script>


