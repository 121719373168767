<template>
	<div :class="openmap">
		<div class="map-container">
			<div id="map1"></div>

			<div class="search">
				<input v-model="searchstring" :placeholder="translate.SEARCH_ZOEKOPCLUB" />
			</div>
		</div>

		<i v-on:click="setactiveproperty('screen', '')" class="closemap bi bi-x tekstblauw"></i>

		<div class="filter">
			<div class="row mb5">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaContinents')" class="select">{{ continent }}</div>
				</div>

				<div class="col-2 middle-right">
					<a class="bi bi-arrow-right-circle-fill tekstblauw" v-if="continent.id > 1" v-on:click="gotocontinent()"></a>
				</div>
			</div>

			<div class="row mb5" v-if="maps.continent?.id > 1">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaCountries')" class="select">{{ country }}</div>
				</div>

				<div class="col-2 middle-right">
					<a v-if="country.id > 0" v-on:click="gotocountry()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>

			<div class="row mb5" v-if="maps.country?.id > 0">
				<div class="col-10">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaRegions')" class="select">{{ region }}</div>
				</div>

				<div class="col-2 middle-right">
					<a v-if="region.id > 0" v-on:click="gotoregion()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>

			<div class="row clubs" v-if="maps.region?.id > 0">
				<div class="col-10 middle">
					<div v-on:click="setactiveproperty('toppanel', 'FilterAreaAreaClubs')" class="select">{{ club }}</div>
				</div>
				<div class="col-2 middle-right">
					<a v-if="maps.club?.id > 0" v-on:click="gotoclub()" class="bi bi-arrow-right-circle-fill tekstblauw"></a>
				</div>
			</div>
		</div>

		<!-- <div v-if="loggedin">
			<a class="button addclub" v-on:click="setactiveproperty('panel', 'AddClub')">{{ translate.SEARCH_ADDCLUB }}</a>
		</div> -->
	</div>
</template>

<script>
import {mapState} from "vuex";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default {
	data() {
		return {
			map: null,
			markers: [],
			currentclubid: null,
			clickedclub: null,
			clickmarker: null,
		};
	},

	methods: {
		initMap() {
			this.map1 = L.map("map1", {preferCanvas: true, zoomControl: true, zoom: 1, zoomAnimation: false, fadeAnimation: true, markerZoomAnimation: true}).setView([0, 0], 1); // Pas deze coördinaten aan naar je eigen voorkeur

			L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
				maxZoom: 19,
				attribution: "© OpenStreetMap contributors",
			}).addTo(this.map1);
		},

		placemarkers() {
			if (this.$debug == 1) {
				console.log("placemarkers");
			}

			const markers = L.markerClusterGroup({
				chunkedLoading: true,
				maxClusterRadius: 50,
			});

			var bounds = L.latLngBounds();

			this.clubs.forEach((club) => {
				var geoposition = club.club_geoposition.split(",");
				var lat = geoposition["0"];
				var lng = geoposition["1"];

				// console.log(club);

				if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
					var iconUrl = club.noclub == "1" ? "https://www.hockeydo.com/images/marker-rood.png" : "https://www.hockeydo.com/images/marker.png";

					const marker = L.marker([lat, lng], {
						icon: L.divIcon({
							className: "custom-div-icon",
							html: `<div style="background-image: url('${iconUrl}');" class="marker-pin"></div><span class="marker-text">${club.club_nameshort}</span>`,
							iconSize: [40, 40],
							iconAnchor: [20, 40],
						}),
					});

					marker.on("click", () => {
						this.clickonmarker(club);
					});

					markers.addLayer(marker);
					bounds.extend(marker.getLatLng());
				}
			});

			this.map1.addLayer(markers);

			setTimeout(() => {
				this.map1.fitBounds(bounds, {animate: false});
			}, 500);

			// Voeg een zoomend event listener toe om labels te tonen/verbergen
			this.map1.on(
				"zoomend",
				function () {
					const currentZoom = this.map1.getZoom();
					const labelElements = document.querySelectorAll(".marker-text");

					labelElements.forEach((label) => {
						if (currentZoom > 7) {
							// Pas dit zoomniveau aan naar wens
							label.style.display = "block";
						} else {
							label.style.display = "none";
						}
					});
				}.bind(this)
			);
		},

		clickonmarker(club) {
			this.setactiveproperty("screen", null);
			this.$router.push({name: "Clubs", params: {continent: club.continentslug, country: club.countryslug, region: club.regionslug, club: club.slug, scope: "clubinfo", sub: "info", subsub: "info"}});
		},

		gotocontinent() {
			if (this.$store.state.current.filterContinent == null) {
				// We zijn in de wereld
				this.$router.push({name: "Posts", params: {continent: "-", country: "-", region: "-", club: "-", scope: "world", sub: "posts", subsub: "all"}});

				this.setactiveproperty("panel", "");
				this.setactiveproperty("screen", null);
			} else {
				var continentslug = this.$store.state.active.filterareacontinent.slug;
				this.$router.push({name: "Posts", params: {continent: continentslug, country: "-", region: "-", club: "-", scope: "continent", sub: "posts", subsub: "all"}});
				this.setactiveproperty("panel", "");
				this.setactiveproperty("screen", null);
			}
		},
		gotocountry() {
			var continentslug = this.$store.state.active.filterareacontinent.slug;
			var countryslug = this.$store.state.active.filterareacountry.slug;

			this.$router.push({name: "Posts", params: {continent: continentslug, country: countryslug, region: "-", club: "-", scope: "country", sub: "posts", subsub: "all"}});
			this.setactiveproperty("panel", "");
			this.setactiveproperty("screen", null);
		},
		gotoregion() {
			var continentslug = this.$store.state.active.filterareacontinent.slug;
			var countryslug = this.$store.state.active.filterareacountry.slug;
			var regionslug = this.$store.state.active.filterarearegion.slug;

			this.$router.push({name: "Posts", params: {continent: continentslug, country: countryslug, region: regionslug, club: "-", scope: "region", sub: "posts", subsub: "all"}});

			this.setactiveproperty("toppanel", "");
			this.setactiveproperty("screen", null);
		},
		gotoclub() {
			var continentslug = this.$store.state.active.filterareacontinent.slug;
			var countryslug = this.$store.state.active.filterareacountry.slug;
			var regionslug = this.$store.state.active.filterarearegion.slug;
			var clubslug = this.$store.state.active.filterareaclub.slug;

			this.$router.push({name: "Posts", params: {continent: continentslug, country: countryslug, region: regionslug, club: clubslug, scope: "club", sub: "posts", subsub: "all"}});

			this.setactiveproperty("panel", "");
			this.setactiveproperty("screen", null);
		},
	},
	computed: {
		...mapState(["current", "active", "config", "maps"]),

		clubs() {
			return this.maps.clubs;
		},

		searchstring: {
			get() {
				return this.$store.state.active.searchstring;
			},
			set(value) {
				var obj = new Object();
				obj.property = "searchstring";
				obj.value = value;
				this.$store.commit("setactiveproperty", obj);
			},
		},

		openmap() {
			if (this.store.state.active.screen == "Map") {
				return "open";
			} else {
				return "close";
			}
		},

		currentscope() {
			if (this.$store.state.active.filterareaclub) {
				return "club";
			}

			if (this.$store.state.active.filterarearegion) {
				return "region";
			}

			if (this.$store.state.active.filterareacountry) {
				return "country";
			}

			if (this.$store.state.active.filterareacontinent) {
				if (this.$store.state.active.filterareacontinent.id == 0) {
					return "world";
				} else {
					return "continent";
				}
			} else {
				return "world";
			}
		},
		continent() {
			if (this.maps.continent) {
				return this.maps.continent[this.active.languagewebsite];
			} else {
				return this.translate.FILTER_KIESCONTINENT;
			}
		},

		country() {
			if (this.maps.country) {
				return this.maps.country[this.active.languagewebsite];
			} else {
				return this.translate.FILTER_KIESLAND;
			}
		},

		region() {
			if (this.maps.region) {
				return this.maps.region.name;
			} else {
				return this.translate.FILTER_KIESREGIO;
			}
		},

		club() {
			if (this.maps.club) {
				return this.maps.club.club_nameshort;
			} else {
				return this.translate.FILTER_KIESCLUB;
			}
		},
	},
	watch: {
		clubs() {
			this.placemarkers();
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.initMap();
		});
	},
};
</script>

<style>
.search {
	position: fixed;
	top: 65px;
	right: 75px;
	left: 45px;
	height: 60px;
	padding: 10px;
	z-index: 10;
}

#map1 {
	position: fixed;
	left: 0px;
	right: 0;
	top: 65px;
	bottom: 0px;
	background: #aad3df;
	opacity: 1;
	z-index: 2;
	transition: opacity 0.1s;
	display: block;
}

.close #map1 {
	opacity: 0;
	z-index: -100;
}

.filter {
	position: fixed;
	top: 130px;
	left: 55px;
	z-index: 3;
}

.addclub {
	position: fixed !important;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3;
}

@media (max-width: 576px) {
	.filter {
		position: fixed;
		top: 150px;
		left: 15px;
		z-index: 3;
	}
}

.filter .bi {
	position: relative;
	left: 15px;
}

.close .filter {
	opacity: 0;
	z-index: -10;
}

.closemap {
	position: fixed;
	top: 75px;
	right: 30px;
	z-index: 3;
	font-size: 40px !important;
	background: #fff;
	border-radius: 50%;
	opacity: 1;
}

.close .map-container {
	z-index: -1;
	opacity: 0;
	position: relative;
}

.close .closemap {
	z-index: 0;
	opacity: 0;
}

.custom-div-icon {
	background: transparent;
	border: none;
}

.marker-pin {
	width: 40px;
	height: 40px;
	background-size: cover;
}

.marker-text {
	position: absolute;
	top: 5px;
	left: 24px;
	white-space: nowrap;
	display: none; /* standaard verborgen */
	background: #3bb58e;
	padding: 3px 7px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	color: #fff;
	font-size: 11px;
	line-height: 11px;
}
</style>
