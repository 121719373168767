<template>     
     <div v-if="twitterurl">
          <div id="twittercontainer"></div>


     </div>
</template>

<script>



export default 
{

     methods: {

          loadtwitter() 
          {
                            
               if (this.$store.state.config.debug==1) 
               {
                    console.log('gettwitter');
               }

       
               var twitterfeed = this.$store.state.current.club.twitterurl.replace("https://twitter.com/", "");  

               if (twitterfeed) 
               {
                    window.twttr.widgets.createTimeline(
                    {
                         sourceType: 'profile',
                         screenName: twitterfeed
                    },
                    document.getElementById('twittercontainer'),
                    {
                         height: window.innerHeight - 180,
                         related: 'twitterdev,twitterapi'
                    }).then(function () 
                    {

                    });

               }
               
 

             

          }                        


     },
     beforeCreate() {

          var externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
          externalScript.setAttribute('charset', 'utf-8')  
          document.head.appendChild(externalScript)
     },
     computed: {

          twitterurl() {
               return this.$store.state.current.club.twitterurl;
          },

     },
 
     mounted() {

          setTimeout(this.loadtwitter, 500); 
    

          
     }
  

}
</script>  
