<template>
	<div class="loading" v-if="!siteloaded">{{ loadingpercentage }}%</div>

	<div class="main" v-if="siteloaded">
		<div id="headercontainer">
			<span class="d-none d-lg-block"><Header /></span>

			<span class="d-block d-lg-none"><MobileHeader /></span>
		</div>

		<div id="leftcontainer">
			<MenuLeft />
		</div>

		<div id="middlecontainer">
			<div id="content">
				<router-view />
			</div>
		</div>

		<div id="rightcontainer"></div>

		<div id="footercontainer">
			<div id="footer">
				<MobileFooter />
			</div>
		</div>

		<div>
			<Overlay />
		</div>

		<div id="panelscontainer">
			<div id="panels">
				<WebsiteLanguage />
				<ItemMenu />

				<Apply />

				<FilterArea />
				<FilterAreaArea />
				<PostsFilterLanguages />
				<PostsFilterCats />
				<PostsFilterLocations />

				<PostCreate />
				<PostComments />

				<Notifications />
				<Bookmarks />

				<UserProfile />

				<Vote />
				<VideoStats />
				<AddClub />
				<AddClubThankyou />
				<CorrectClub />
				<CorrectClubThankyou />
				<Login />

				<CreateComment />

				<RankingsFilter />

				<Menu />

				<PlaysPlayTeams />
				<PlaysPlayOvertredingen />
				<PlaysPlayKaarten />
				<PlaysPlayStraffen />
				<GamesMenu />
			</div>
		</div>

		<div id="toppanelscontainer">
			<div id="panels">
				<SetUserClubConfirmation />

				<FilterAreaAreaContinents />
				<FilterAreaAreaCountries />
				<FilterAreaAreaRegions />
				<FilterAreaAreaClubs />

				<PostCreateLanguage />
				<PostCreateLocation />
				<PostCreateCats />

				<PostCreateAnswer />

				<UserProfileWebsiteLanguages />
				<UserProfileWriteLanguages />
				<UserProfileReadLanguages />
				<UserProfileActivities />
				<UserProfileFunctions />
				<UserProfileLocations />
				<UserProfileRemoveaccount />
			</div>
		</div>

		<div id="screens">
			<Map />
		</div>

		<Search />
	</div>
</template>

<script>
// Interactions
import PostComments from "@/components/interactions/panels/ShowComments.vue";
import PostCreateAnswer from "@/components/interactions/panels/CreateAnswer.vue";
import CreateComment from "@/components/interactions/panels/CreateComment.vue";

// common

import MenuLeft from "@/components/common/MenuLeft.vue";
import Header from "@/components/common/Header.vue";
import MobileFooter from "@/components/common/MobileFooter.vue";
import MobileHeader from "@/components/common/MobileHeader.vue";
import Overlay from "@/components/common/Overlay.vue";
import WebsiteLanguage from "@/components/common/WebsiteLanguage.vue";
import ItemMenu from "@/components/common/panels/ItemMenu.vue";
import Search from "@/components/common/Search.vue";
import Map from "@/components/common/screens/Map.vue";

// users
import Bookmarks from "@/components/users/panels/Bookmarks.vue";
import Notifications from "@/components/users/panels/Notifications.vue";
import SetUserClubConfirmation from "@/components/users/panels/SetUserClubConfirmation.vue";
import Login from "@/components/users/panels/Login.vue";
import UserProfile from "@/components/users/panels/UserProfile.vue";
import UserProfileWebsiteLanguages from "@/components/users/panels/UserProfileWebsiteLanguages.vue";
import UserProfileWriteLanguages from "@/components/users/panels/UserProfileWriteLanguages.vue";
import UserProfileReadLanguages from "@/components/users/panels/UserProfileReadLanguages.vue";
import Menu from "@/components/users/panels/Menu.vue";
import UserProfileActivities from "@/components/users/panels/UserProfileActivities.vue";
import UserProfileFunctions from "@/components/users/panels/UserProfileFunctions.vue";
import UserProfileLocations from "@/components/users/panels/UserProfileLocations.vue";

import UserProfileRemoveaccount from "@/components/users/panels/UserProfileRemoveaccount.vue";

//Clubs

import AddClub from "@/components/clubs/panels/AddClub.vue";
import AddClubThankyou from "@/components/clubs/panels/AddClubThankyou.vue";
import CorrectClub from "@/components/clubs/panels/CorrectClub.vue";
import CorrectClubThankyou from "@/components/clubs/panels/CorrectClubThankyou.vue";

// Posts

import PostsFilterLanguages from "@/components/posts/PostsFilterLanguages.vue";
import PostsFilterLocations from "@/components/posts/PostsFilterLocations.vue";
import PostsFilterCats from "@/components/posts/PostsFilterCats.vue";

import PostCreate from "@/components/posts/panels/PostsCreate.vue";

import PostCreateLanguage from "@/components/posts/panels/PostsCreateLanguage.vue";
import PostCreateLocation from "@/components/posts/panels/PostsCreateLocation.vue";
import PostCreateCats from "@/components/posts/panels/PostsCreateCats.vue";

// We
import Vote from "@/components/we/panels/Vote.vue";
import Apply from "@/components/we/panels/Apply.vue";

// Games
import VideoStats from "@/components/games/panels/VideoStats.vue";
import RankingsFilter from "@/components/games/panels/RankingsFilter.vue";
import PlaysPlayTeams from "@/components/games/panels/PlaysPlayTeams.vue";
import PlaysPlayOvertredingen from "@/components/games/panels/PlaysPlayOvertredingen.vue";
import PlaysPlayKaarten from "@/components/games/panels/PlaysPlayKaarten.vue";
import PlaysPlayStraffen from "@/components/games/panels/PlaysPlayStraffen.vue";
import GamesMenu from "@/components/games/panels/GamesMenu";

// Filters

import FilterAreaArea from "@/components/common/panels/FilterAreaArea.vue";
import FilterAreaAreaContinents from "@/components/common/panels/FilterAreaAreaContinents.vue";
import FilterAreaAreaCountries from "@/components/common/panels/FilterAreaAreaCountries.vue";
import FilterAreaAreaRegions from "@/components/common/panels/FilterAreaAreaRegions.vue";
import FilterAreaAreaClubs from "@/components/common/panels/FilterAreaAreaClubs.vue";
import FilterArea from "@/components/common/panels/FilterArea.vue";

export default {
	data() {
		return {
			minimalloading: 9,
			hasuser: false,
			timer: null,
		};
	},

	components: {
		Header,
		MenuLeft,
		MobileFooter,
		PostCreate,
		FilterArea,
		FilterAreaArea,
		FilterAreaAreaContinents,
		FilterAreaAreaCountries,
		FilterAreaAreaRegions,
		FilterAreaAreaClubs,
		PostsFilterLanguages,
		PostsFilterLocations,
		PostsFilterCats,
		WebsiteLanguage,

		Search,
		Apply,
		Map,
		GamesMenu,
		MobileHeader,
		PostComments,
		Notifications,
		Bookmarks,
		SetUserClubConfirmation,
		UserProfile,
		ItemMenu,
		Vote,
		VideoStats,
		AddClub,
		AddClubThankyou,
		CorrectClub,
		CorrectClubThankyou,
		Overlay,
		Login,
		CreateComment,
		PostCreateAnswer,
		RankingsFilter,

		PostCreateLanguage,
		PostCreateLocation,
		UserProfileWebsiteLanguages,
		UserProfileWriteLanguages,
		UserProfileReadLanguages,
		UserProfileRemoveaccount,

		Menu,
		UserProfileActivities,
		UserProfileFunctions,
		UserProfileLocations,
		PlaysPlayTeams,
		PlaysPlayOvertredingen,
		PlaysPlayKaarten,
		PlaysPlayStraffen,
		PostCreateCats,
	},

	methods: {
		setstandardconfig() {
			var domain = window.location.hostname;

			if (domain == "dev.hockeydo.com") {
				this.$store.commit("setdebug", 0);
			}

			if (domain == "hockeydo.com") {
				this.$store.commit("setdebug", 0);
			}

			this.$store.commit("setuser", null);

			this.setactiveproperty("languagewebsite", "en"); // website taal op engels zetten
			this.setactiveproperty("filterlanguagesids", "1,"); // filter op alle talen zetten

			this.setactiveproperty("filtercat", null); // filter alles zetten

			const filterlocation = this.getobjectbyid(this.$store.state.static.locations, "1");
			this.setactiveproperty("filterlocation", filterlocation); // filter op veld en zaal zetten

			this.setactiveproperty("panel", "");
			this.setactiveproperty("toppanel", "");

			this.$store.commit("increaseloading");

			if (this.$store.state.config.debug == 1) {
				console.log("setstandardconfig fetched");
			}

			this.numberofrequireddata++;
		},

		getbrowserlanguage() {
			var browserlanguage = navigator.language.split("-")[0];

			var language = "en";

			if (browserlanguage == "nl") {
				language = "nl";
			}
			if (browserlanguage == "de") {
				language = "de";
			}
			if (browserlanguage == "fr") {
				language = "fr";
			}
			if (browserlanguage == "es") {
				language = "es";
			}
			if (browserlanguage == "pt") {
				language = "pt";
			}
			if (browserlanguage == "it") {
				language = "it";
			}

			this.setactiveproperty("languagewebsite", language);

			if (this.$store.state.config.debug == 1) {
				console.log("browserlanguage fetched");
			}

			this.$store.commit("increaseloading");
		},

		gotouserclub() {
			if (this.$store.state.config.debug == 1) {
				console.log("gotuserclub");
			}

			var club = this.$store.state.user.club;

			if (club) {
				this.$router.push({
					name: "Posts",
					params: {
						continent: club.continent_slug,
						country: club.country_slug,
						region: club.region_slug,
						club: club.slug,
						scope: "club",
						sub: "posts",
						subsub: "all",
					},
				});
			}
		},

		setusersettings() {
			if (this.$store.state.config.debug == 1) {
				console.log("setusersettings");
			}

			// Wanneer een gebruiker inlogt zijn voorkeuren instellen

			if (this.$store.state.user) {
				this.setactiveproperty("languagewebsite", this.$store.state.user.languagewebsite);

				if (this.$store.state.user.clubid < 1) {
					this.setactiveproperty("panel", "");
					this.setactiveproperty("screen", "Map");
				}
			}

			var postcat = this.getobjectbyid(this.$store.state.static.postcats, "1");
			this.setactiveproperty("postcat", postcat);
		},

		login() {
			// INLOGGEN ==========================================================================================

			// Wanneer we net via facebook ingelogd hebben, krijgen we een hdaccestoken mee.

			const urlParams = new URLSearchParams(window.location.search);
			const accesstoken = urlParams.get("hdaccesstoken");

			// Wanneer ik een accestoken heb, sla ik het op en stuur de browser naar een nette URL.

			if (accesstoken) {
				if (this.$store.state.config.debug == 1) {
					console.log("hdaccestoken ontvangen");
				}

				this.$cookie.set("hockeydo", accesstoken);

				var host = window.location.hostname;

				if (host == "localhost") {
					window.location.replace("http://localhost:8080/");
				}

				if (host == "dev.hockeydo.com") {
					window.location.replace("http://dev.hockeydo.com/");
				}

				if (host == "www.hockeydo.com") {
					window.location.replace("https://hockeydo.com/");
				}

				if (host == "192.168.178.138") {
					window.location.replace("http://192.168.178.138:8080");
				}
			}

			// Wanneer er een cookie met accesstoken is ben ik ingelogd;

			const hdaccesstoken = this.$cookie.get("hockeydo");
			if (hdaccesstoken !== null) {
				if (this.$store.state.config.debug == 1) {
					console.log("INGELOGD");
				}
				this.$store.dispatch("fetchuser");
			} else {
				// Er is nog niet ingelogd

				if (this.$store.state.config.debug == 1) {
					console.log("NIET INGELOGD");
				}
			}
		},

		onresize() {
			var width = window.innerWidth;
			this.setactiveproperty("windowwidth", width);

			var contentwidth = width;

			var text = "XS";

			if (width > 575) {
				text = "SM";
			}
			if (width > 767) {
				text = "MD";

				contentwidth = width - 320;

				if (contentwidth > 1000) {
					contentwidth = 1000;
				}
			}
			if (width > 991) {
				text = "LG";

				contentwidth = width - 600;

				if (contentwidth > 800) {
					contentwidth = 800;
				}
			}

			if (width > 1199) {
				text = "XL";
			}

			this.setactiveproperty("contentwidth", contentwidth);

			document.body.classList.remove("XS");
			document.body.classList.remove("SM");
			document.body.classList.remove("MD");
			document.body.classList.remove("LG");
			document.body.classList.remove("XL");
			document.body.classList.add(text);
		},
	},
	// ==================================================================================================
	computed: {
		routeparamschanges() {
			return this.$route.params;
		},

		userlanguagewebsitechanges() {
			if (this.$store.state.user) {
				return this.$store.state.user.languagewebsite;
			} else {
				return null;
			}
		},

		languagewebsitechanges() {
			return this.$store.state.active.languagewebsite;
		},

		taskchanges() {
			return this.$store.state.active.task;
		},

		userchanges() {
			if (this.$store.state.user) {
				return this.$store.state.user.id;
			} else {
				return null;
			}
		},

		sectionchanges() {
			return this.$route.name;
		},

		siteloaded() {
			if (this.loadingpercentage > 99) {
				return true;
			} else {
				return false;
			}
		},

		loadingpercentage() {
			return parseInt((this.$store.state.active.loading / this.minimalloading) * 100);
		},

		continentchanges() {
			return this.$route.params.continent;
		},
		countrychanges() {
			return this.$route.params.country;
		},
		regionchanges() {
			return this.$route.params.region;
		},
		clubchanges() {
			return this.$route.params.club;
		},

		routescope() {
			return this.$route.params.scope;
		},
		userclubidchanges() {
			if (this.$store.state.user) {
				return this.$store.state.user.clubid;
			} else {
				return null;
			}
		},
		userclubchanges() {
			if (this.$store.state.user) {
				if (this.$store.state.user.club) {
					return this.$store.state.user.club;
				} else {
					return null;
				}
			} else {
				return null;
			}
		},
	},

	watch: {
		// HET OPBOUWEN VAN DE SITE
		siteloaded() {
			console.log("SITE LOADED =======================================");
			this.setstandardconfig();
			this.login();
		},

		userchanges() {
			if (this.$store.state.user) {
				console.log("USERCHANGES");

				this.setusersettings();
				this.$store.dispatch("fetchcompetitions");
			}
		},

		//==================================================================================

		userclubchanges() {
			if (this.$store.state.user.club) {
				// Wanneer de gebruiker inlogd, de club op zijn userclub zetten.

				this.$store.dispatch("fetchcontinent", this.$store.state.user.club.continent_slug);
				this.$store.dispatch("fetchcountry", this.$store.state.user.club.country_slug);
				this.$store.dispatch("fetchregion", this.$store.state.user.club.region_slug);
				this.$store.dispatch("fetchclub", this.$store.state.user.club.slug);
			}
		},

		userlanguagewebsitechanges() {
			this.setusersettings();
		},

		languagewebsitechanges() {
			this.$store.dispatch("fetchtranslations");
		},

		routeparamschanges() {
			this.$store.commit("setroute", this.$route.params);
			this.$store.commit("setroutename", this.$route.name);
		},

		sectionchanges() {
			this.setactiveproperty("section", this.$route.name.toLowerCase());
		},

		continentchanges() {
			if (this.$route.name == "Clubs" || this.$route.name == "Posts") {
				this.$store.dispatch("fetchcontinent", this.$route.params.continent);
			}
		},
		countrychanges() {
			if (this.$route.name == "Clubs" || this.$route.name == "Posts") {
				this.$store.dispatch("fetchcountry", this.$route.params.country);
			}
		},
		regionchanges() {
			if (this.$route.name == "Clubs" || this.$route.name == "Posts") {
				this.$store.dispatch("fetchregion", this.$route.params.region);
			}
		},
		clubchanges() {
			if (this.$route.name == "Clubs" || this.$route.name == "Posts") {
				this.$store.dispatch("fetchclub", this.$route.params.club);
			}
		},
		routescope() {
			if (this.$route.name == "Posts") {
				this.setactiveproperty("scope", this.$route.params.scope);
			}
		},
		taskchanges() {
			if (this.$store.state.active.task == "refresh") {
				if (this.$store.state.active.taskitemtype == "club") {
					this.$store.dispatch("fetchclub", this.$route.params.club);

					this.setactiveproperty("task", null);
					this.setactiveproperty("taskitemtype", null);
					this.setactiveproperty("taskitemid", null);
				}
			}
		},

		userclubidchanges() {
			if (this.$store.state.user) {
				this.$store.dispatch("fetchuserclub");
			}
		},
	},

	created() {
		setTimeout(this.getcontainerwidth, 1000);
		window.addEventListener("resize", this.onresize);
		this.onresize();
	},

	unmounted() {
		window.removeEventListener("resize", this.onresize);
		clearInterval(this.timer);
	},

	// ====================================================================================

	mounted() {
		// HET LADEN VAN DE SITE

		if (this.$store.state.config.debug == 1) {
			console.log("RELOAD ==============================================");
		}

		this.getbrowserlanguage();
		this.$store.dispatch("fetchclubs");
		this.$store.dispatch("fetchlanguages");
		this.$store.dispatch("fetchuseractivities");
		this.$store.dispatch("fetchuserfunctions");
		this.$store.dispatch("fetchpostcats");
		this.$store.dispatch("fetchlocations");
		this.$store.dispatch("fetchtranslations");
		this.$store.dispatch("fetchcompetitions");
		this.$store.dispatch("fetchtimelinerules");
		this.$store.dispatch("fetchContinentsCountriesRegions");
		this.$store.dispatch("fetchManual");
		this.$store.dispatch("fetchteams");
		this.$store.dispatch("fetchovertredingen");
		this.$store.dispatch("fetchstraffen");
		this.$store.dispatch("fetchkaarten");
	},
};
</script>

<style>
@import "./assets/css/multiselect.css";
@import "./assets/css/grid.css";
@import "./assets/css/basis.css";
@import "./assets/css/standards.css";

@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600;700;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
</style>
