<template>
	<svg class="icon game active" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
		<path d="m467.51 248.83c-18.4-83.18-45.69-136.24-89.43-149.17a91.5 91.5 0 0 0 -26.08-3.66c-26.89 0-48.11 16-96 16s-69.15-16-96-16a99.09 99.09 0 0 0 -27.2 3.66c-43.8 12.93-70.86 66.04-89.47 149.17-19 84.91-15.56 152 21.58 164.88 26 9 49.25-9.61 71.27-37 25-31.2 55.79-40.8 119.82-40.8s93.62 9.6 118.66 40.8c22 27.41 46.11 45.79 71.42 37.16 41.02-14.01 40.44-79.13 21.43-165.04z" fill="#21BAA5" stroke="#21BAA5" stroke-miterlimit="10" stroke-width="32" />
		<circle cx="292" cy="224" r="20" stroke="#ffffff" fill="#ffffff" />
		<path d="m336 288a20 20 0 1 1 20-19.95 20 20 0 0 1 -20 19.95z" stroke="#ffffff" fill="#ffffff" />
		<circle cx="336" cy="180" r="20" stroke="#ffffff" fill="#ffffff" />
		<circle cx="380" cy="224" r="20" stroke="#ffffff" fill="#ffffff" />
		<g fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="32">
			<path d="m160 176v96" />
			<path d="m208 224h-96" />
		</g>
	</svg>
</template>
