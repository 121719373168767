<template>

     <div v-if="club" class="panel_section" >

          <div class="row">

               <div class="links">          
                    <img class="clublogo floatleft mr10" :src="clubLogoUrl"  @error="setAltImg" />
               </div>     

               <div class="midden klein tekstgrijs">
                    <span class="tekstzwart dik">{{club.name_long}} </span> 
                     &bull; {{ club.place }} &bull; {{clubcountry}} &bull; {{clubcontinent}}
               </div>  

               <div class="rechts verticalcenter horizontalright">
                         <a v-on:click="gotoclub()"><i class="tekstblauw bi bi-chevron-right"></i></a>
                </div>   

          </div>
          

          
     </div>

  
     
</template>


<script>
import axios from "axios";

export default{
    name:"BookmarksClubs",
     data(){
          return {    
               club: null           
          }
     },
     props: ["id"],


     methods: {

          gotoclub(){

               this.$router.push({ name: 'Clubs', params:{ continent: this.club.continent_slug, country: this.club.country_slug,region: this.club.region_slug,club: this.club.slug,scope: 'clubinfo',sub: 'info', subsub: 'info' } })
              
               this.setactiveproperty('panel','');


          },

          getclub()  
          {
             

               if (this.$store.state.config.debug==1) 
               {
                    console.log('getclub');
               }    
               
      
               var post = new Object();
               post.task = 'getclub';
               post.clubid =  this.id;

      


               axios.post( this.$store.state.config.apiserver + 'users/panels/BookmarksClubs.php', post)
               .then(response => {
                    this.club = response.data;
                 
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });

          },


     },

     computed:{          
   

          clubcountry() {
               return this.club['country' + this.$store.state.active.languagewebsite];           
          },
          clubcontinent() {
               return this.club['continent_' + this.$store.state.active.languagewebsite];           
          },
          clubLogoUrl() {
               return this.$store.state.config.logosurl + this.club.id + '.png'

          },    

     
     },
          mounted() {
               this.getclub() 
          }
     }
</script>

