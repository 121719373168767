<template>

<div class="submenu">   

     <div class="submenu_menu">
      
          <router-link  :to="{name: 'We', params:{ group: 'referees', view: 'info' }}"><div class="submenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_GROUP}}</div></router-link>    
     
     </div>   

</div>

<div class="subsubmenu">


     <div class="subsubmenu_menu">
      

          <div v-if="this.store.state.we.group.userstatus=='admitted'">
               
               <router-link  :to="{name: 'We', params:{ group: 'referees', view: 'posts' }}"><div class="subsubmenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_POSTS}}</div></router-link>     
          
          
          
          </div>
 
          
          <router-link  :to="{name: 'We', params:{ group: 'referees', view: 'info' }}"><div class="subsubmenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_INFO}}</div></router-link>  
          
          
          <div v-if="this.store.state.we.group.userstatus=='prospect'">
               
               <router-link  :to="{name: 'We', params:{ group: 'referees', view: 'prospectives' }}"><div class="subsubmenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_PROSPECTS}}</div></router-link>    
         
          
          </div>
          
          <div v-if="this.store.state.we.group.userstatus=='admitted'">
               
               <router-link  :to="{name: 'We', params:{ group: 'referees', view: 'prospectives' }}"><div class="subsubmenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_PROSPECTS}}</div></router-link>    
         
          
          </div>

      
         
          <router-link v-if="this.store.state.we.group.userstatus=='admitted'" :to="{name: 'We', params:{ group: 'referees', view: 'members' }}"><div class="subsubmenu_menu_item" :class="(scope=='club')?'active':''">{{translate.WEHEADER_MEMBERS}}</div></router-link>    
        
          
     </div>   

   
    

</div>     

     
</template>


<script>
//import axios from "axios";

export default{
    
     data(){
          return {    
                              
          }
     },

     components: {
         
     },
     computed:{          
   
        scope(){

               return null

        }

          
     },
     watch: {



     },


     methods: {




     




     }
}
</script>

