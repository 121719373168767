<template>

     <div v-if=" group">
                 
          <Header/>
          
          <Info v-if="view=='info'" />
          <Prospectives v-if="view=='prospectives'" />
          <Members v-if="view=='members'" />
          <Posts v-if="view=='posts'" />


          
     </div>
     
</template>


<script>

import Header from '@/components/we/WeHeader.vue'

import Info from '@/components/we/views/GroupInfo.vue'
import Prospectives from '@/components/we/views/GroupProspectives.vue'
import Members from '@/components/we/views/GroupMembers.vue'
import Posts from '@/components/we/views/Posts.vue'




export default{

     data(){
          return {    

       
                              
          }
     },
     components: {
          Info,
          Prospectives,
          Members,
          Posts,
          Header
     },
     methods: {
          


     },

     computed: {

          view (){
               
               return this.$route.params.view;
   
             
          },

          group(){

               return this.$store.state.we.group;
          }


     },
   

     mounted() {
      
          this.$store.dispatch('fetchgroup');
      
     }
  
}







</script>
