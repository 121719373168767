<template>


     <transition name="slideup">


   
          <div v-if="this.store.state.active.toppanel=='UserProfileRemoveaccount'" class="panel">

               <div v-if="this.store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

               <div class="row panel_header">
                    <div class="col-6 middle">
                         <h1>{{translate.USERPROFILE_REMOVEACCOUNT}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                        
                              <a v-on:click="setactiveproperty('toppanel','')"  class="bi bi-x tekstblauw floatright"></a>
                         
                    </div> 

               </div>   


               <div class="panel_content aligncenter" >




                    <br><br><br>


         
     
                         <h1>{{translate.USERPROFILE_REMOVEACCOUNT_TEKST}}</h1>




                         <a v-on:click="removeaccount()" class="button">{{translate.USERPROFILE_REMOVEACCOUNT_REMOVE}}</a>


                         <br><br>
              

               {{ this.$store.state.user.id }}


                    

              
     

               

                              

               



                    
                         
     
                    
               </div>

      


               

          </div> <!-- panel -->
     </transition>     
</template>


<script>

import axios from "axios";



export default{

     data() {
          return{
               panelname: 'UserProfile',
  
          
               
          }
     },
 
    computed:{  

          
  

          

    },


    methods: {

      
            logout() {

               this.$cookie.remove('hockeydo');

               var host = location.hostname;

               if (host=='www.hockeydo.com') {

                    window.location.replace("https://www.hockeydo.com");

               } else {       
                    
                    
                    host = 'http://' + location.hostname + ':' + location.port;          

                    window.location.replace(host);

               }



          },


          removeaccount(){

               if (this.$store.state.config.debug==1) 
               {
               console.log('removeaccount');
               }    

               var post = new Object();
               post.task = 'removeaccount';
               post.hdaccesstoken = this.$cookie.get('hockeydo'); 

               //console.log(post);

               axios.post( this.$store.state.config.apiserver + 'users/panels/UserProfile.php', post)  
               .then(response => 
               {                   
                    
                    if (this.$store.state.config.debug==1) 
                    {
                    console.log(response.data);
                    }  

                    
                    this.logout()

                    
               })
               .catch(error => {
               this.errorMessage = error.message;
               console.error("There was an error!", error);
               });








          }

      


    },

}
</script>





