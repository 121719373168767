<template>
 
    <Posts/>
 
</template>

<script>

// import axios from "axios";
import Posts from '@/components/posts/Posts.vue'

export default { 

     data(){
          return {    
               group: null,              
               
          }
     },
 
     components: {
     Posts
     },
     methods: {

          


     },
     mounted() {

      

     }



}
</script>
