<template> 
     
     <div class="submenu">   

          <div class="submenu_menu">
               <img v-if="clubLogoUrl" class="clublogo" :src="clubLogoUrl"  @error="setAltImg" />
               <router-link  v-if="currentClub" :to="{name: 'Posts', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'club',sub: 'posts',subsub: 'all' }}"><div class="submenu_menu_item" :class="(scope=='club')?'active':''">{{currentClub.name_long}}</div></router-link>    
               <router-link  v-if="currentRegion && routename=='Posts'" :to="{name: 'Posts', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'region',sub: 'posts',subsub: 'all' }}"><div class="submenu_menu_item" :class="(scope=='region')?'active':''">{{ currentRegion.name }}</div></router-link>           
               <router-link  v-if="currentCountry && routename=='Posts'" :to="{name: 'Posts', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'country',sub: 'posts', subsub: 'all' }}"><div class="submenu_menu_item" :class="(scope=='country')?'active':''">{{ currentCountryName }}</div></router-link>                     
               <router-link  v-if="currentContinent && routename=='Posts'" :to="{name: 'Posts', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'continent',sub: 'posts', subsub: 'all' }}"><div class="submenu_menu_item" :class="(scope=='continent')?'active':''">{{ currentContinentName }}</div></router-link>          
               <router-link  v-if="routename=='Posts'" :to="{name: 'Posts', params:{ continent: routecontinent, country: routecountry,region: routeregion,club: routeclub,scope: 'world',sub: 'posts', subsub: 'all' }}"><div class="submenu_menu_item last" :class="(scope=='world')?'active':''" >World</div></router-link>    
               <a class="selectlocation" @click="this.setactiveproperty('panel','FilterAreaArea')" ><i class="bi bi-sliders"></i></a>
          </div>   
               
      

      </div>     

 
</template>


<script>



export default{
     name:"Location",
     data(){
          return {    
                              
          }
     },
     methods: {

          setAltImg(event) {
               event.target.src = this.$store.state.config.logosurl + 'nologo.png';
          },

     },
     computed:{          
   

          currentClub() {
               return this.$store.state.current.club
          },
          currentRegion() {
               return this.$store.state.current.region
          },
          currentCountry() {
               return this.$store.state.current.country
          },
          currentContinent() {
               return this.$store.state.current.continent
          },
          currentCountryName() 
          {       
               return this.$store.state.current.country[this.$store.state.active.languagewebsite];
          },   
          currentContinentName() 
          {       
               return this.$store.state.current.continent[this.$store.state.active.languagewebsite];
          },
          scope() {
               return this.$route.params.scope;               
          },
          clubLogoUrl() {

               if (this.$store.state.current.club){
                    return this.$store.state.config.logosurl + this.$store.state.current.club.id + '.png'
               } else {
                    return null;
               }
               

          },
          routecontinent() {
               return this.$route.params.continent
          },
          routecountry(){
               return  this.$route.params.country
          },
          routeregion(){
               return  this.$route.params.region
          },
          routeclub(){
               return  this.$route.params.club
          },
          params() {
               
               return  this.$route.params
          },
          routename() {
               
               return  this.$route.name;
          },

          canseeclubmembers() {

               // Alleen clubleden mogen de leden zien
               
               if (this.$store.state.user.clubid == this.$store.state.current.club.id ) {                    
                    return true
               } else 
               {
                    return false
               }

               
          }

   
     },
    
     
}
</script>

<style scoped>

.selectlocation{
position: absolute;
right: -10px;
top: 7px;
}

.selectlocation .bi{
   
     padding: 5px;
     background: #fff;
     color: var(--blauw);
}


.searchbutton{
position: absolute;
right: 0px;
top: 0px;     
display: inline-block;
background: var(--blauw);
color: #fff;
padding: 13px 15px;
border-radius: 25px;

}
.searchbutton i{
font-size: 16px;
}

.last{
margin-right: 50px;  
}

</style>

