<template>


     <div v-if="this.$store.state.posts.postids.length>0">     
          
          
    
     
          <div class="posts" id="postscontainer">     


  

                    <div v-for="postid in this.$store.state.posts.postids" :key="postid">  
                         <Post v-bind:postid="postid.id"/>
                    </div>
          
               
     
          
          
          </div>





     </div>

     <div v-else class="view">
          

          {{translate.POSTS_NOPOSTS}}

 

     </div>    

 

     <div v-on:click="createpost()">
     <a v-if="usercanwrite"  class="addpost blauw"><i class="bi bi-plus"></i></a>
     <a  v-if="!usercanwrite" class="addpost grijs"><i class="bi bi-plus"></i></a>
     </div>


</template>

<script>


import Post from '@/components/posts/PostsPost.vue';


// import axios from "axios";

export default {

     name: 'Posts',

     data() {
          return{
               loading: false            
          }
     },

     components: {
         
          Post        
     },
     methods:
     { 
          createpost(){


               if (this.loggedin){
                    this.setactiveproperty('panel','PostCreate');
               } else {
                    this.setactiveproperty('panel','Login');
               }

               
               

          },
          
          infiniteScroll() 
          {       
               
               if (this.$route.params.sub=="posts")
               {

                    if (window.scrollY > (document.body.offsetHeight - window.outerHeight- 200)) 
                    {               
                         if(this.$store.state.posts.postloading==false) {

                         
                              this.$store.dispatch('fetchpostids'); 
                         }
                    }
               }
          }


     },
     computed:
     {
          taskchanges(){

               return this.$store.state.active.task;

          },
          
          filterlanguagesidschanges()
          {
               return this.$store.state.active.filterlanguagesids;
          },        

          filterlocationchanges() {
               return this.$store.state.active.filterlocation;
          },
          gamesvideochanges() {
               return this.$store.state.active.gamesvideoid;
          },
          filtercatchanges(){
               return this.$store.state.active.filtercat;
          },
          sectionchanges(){

               return this.$store.state.active.section;

          },   
          
          usercanwrite() {
          if (this.loggedin) 
          {

              

     


               if (this.$route.name=="Home") 
               {
                    
                    return false;
               }    


               if (this.$route.name=="Games") 
               {

                    return true;
               }     

               if (this.$route.name=="Posts") 
               {


                    // Admins mogen overal posten
                    if (this.$store.state.user.userrole == "2") {
                    return true
                    } 
                    
                    if (this.$store.state.user.club)   
                    {

                         if(this.$route.params.scope=='club') {
                              if (this.$store.state.user.club.slug==this.$route.params.club) {
                                   return true
                              } else {
                                   return false
                              }
                         }   
                         
                         if(this.$route.params.scope=='region') {
                              if (this.$store.state.user.club.region_slug==this.$route.params.region) {
                                   return true
                              } else {
                                   return false
                              }
                         }  
                         
                         if(this.$route.params.scope=='country') {
                              if (this.$store.state.user.club.country_slug==this.$route.params.country) {
                                   return true
                              } else {
                                   return false
                              }
                         }   

                         if(this.$route.params.scope=='continent') {
                              if (this.$store.state.user.club.continent_slug==this.$route.params.continent) {
                                   return true
                              } else {
                                   return false
                              }
                         }   
                         
                         
                    }     

                    

               } 


          }  else {

               return false
          } 

          return false


          },   
      

         

     }, 

    watch: {

     

          $route() {

               if (this.$route.name=='Posts') 
               {
          
                    this.$store.commit('deletepostsids',''); 
                    this.$store.dispatch('fetchpostids');      

               }
               
               
          }, 

          filterlanguagesidschanges() 
          {       
             
               this.$store.commit('deletepostsids',''); 
               this.$store.dispatch('fetchpostids');                
          },  

          filterlocationchanges() {               
           
           
               this.$store.commit('deletepostsids',''); 
               this.$store.dispatch('fetchpostids');                    
               
          },
          filtercatchanges(){
          
               this.$store.commit('deletepostsids',''); 
               this.$store.dispatch('fetchpostids');   

          },

          gamesvideochanges() {               
           
               this.$store.commit('deletepostsids','');  
               this.$store.dispatch('fetchpostids');                    
               
          },

          sectionchanges() {               
           
           this.$store.commit('deletepostsids','');  
           this.$store.dispatch('fetchpostids');                    
           
      },


    },





      
    mounted() {      
         
        
         window.addEventListener('scroll', this.infiniteScroll);   
         
         this.$store.dispatch('fetchpostids'); 
    }


  
}
</script>

<style scoped>


.addpost i{

position: fixed;
z-index: 19;
bottom: 70px;
right: 30px;
padding: 15px;
background: var(--blauw);
color: var(--wit);
font-size: 45px;
border-radius: 50px;
}


</style>