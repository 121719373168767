<template>

     <transition name="slideup">

          <div v-if="this.store.state.active.panel=='CorrectClub'" class="panel">

 

               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.CORRECTCLUB_CORRIGEER}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="setactiveproperty('panel','')"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-if="club.correctie=='ja'"> 
                         
                         {{translate.CORRECTCLUB_WORDTAANGEPAST}}

                    </div>

                    <div v-else>

                         <form @submit="postdata">

                              <div class="row">

                                   <div class="col col-12 mb15">
                                        {{translate.CORRECTCLUB_INLEIDING}}  
                                   </div>    


                                   <div v-if="clubitems" class="col col-12"> 
                                        <div v-for="(value, key) in post" :key="key">


                                             <div class="klein ml15 pb5">{{gettranslation(key)}}</div>

                                             <input v-model="post[key]" :placeholder="getplaceholder(key)" />
                                        
                                        
                                        </div>
                                   </div>


               

                                   <div class="col col-12">
                                             <button class="floatright" type="submit"> {{translate.ADDCLUB_VERZENDEN}}</button>
                                   </div>      

                         

                              </div>

                         </form>    

                    </div>      


               

          
               </div>






          </div>
     </transition>     
  
</template>

<script>

import axios from "axios";




export default {

 
     data() {
          return{

               post:{   
                    name_short: null,
                    name_long: null,
                    address: null,
                    place: null,
                    country_en: null,
                    region: null,
                    continent_en: null,
                    websiteurl: null,
                    facebookurl: null,
                    twitterurl: null,
                    youtubechannel: null,
                    instagrampage: null,
                    geoposition: null,
                    indoor_1_name: null,
                    indoor_1_address: null,
                    indoor_1_place: null,
                    indoor_1_geoposition: null,
                    indoor_2_name: null,
                    indoor_2_address: null,
                    indoor_2_place: null,
                    indoor_2_geoposition: null,
                    indoor_3_name: null,
                    indoor_3_address: null,
                    indoor_3_place: null,
                    indoor_3_geoposition: null,
                    indoor_4_name: null,
                    indoor_4_address: null,
                    indoor_4_place: null,
                    indoor_4_geoposition: null,
                    outdoor_1_name: null,
                    outdoor_1_address: null,
                    outdoor_1_place: null,
                    outdoor_1_geoposition: null,
                    outdoor_2_name: null,
                    outdoor_2_address: null,
                    outdoor_2_place: null,
                    outdoor_2_geoposition: null,
                    outdoor_3_name: null,
                    outdoor_3_address: null,
                    outdoor_3_place: null,
                    outdoor_3_geoposition: null,
                    outdoor_4_name: null,
                    outdoor_4_address: null,
                    outdoor_4_place: null,
                    outdoor_4_geoposition: null,
          


               },
                                   
               
          }
     },


     methods:{

          getplaceholder(key){


               const placeholder = this.clubitems.find(obj => obj.key === key);


               return placeholder.value;
          },

          gettranslation(key) {
               return this.translate['CORRECTCLUB_' + key]
          },

          postdata(e) {

               e.preventDefault();


         


               const posten = new Object();

               var aantal = 0;

               Object.keys(this.post).forEach(key => {


                    if (this.post[key]) {

                         console.log(this.post[key]);

                         posten[key] = this.post[key];
                         aantal ++;                    
                    
                    }    
                    
                    posten['clubid'] = this.$store.state.current.club.id;

                    
               });   

               console.log(posten);


               if (aantal>0)
               {

                    if (this.$store.state.config.debug==1) 
                    {
                    console.log('correctclub');
                    }

                    console.log(posten);

                    posten.task = 'correctclub';
                    posten.hdaccesstoken = this.$cookie.get('hockeydo'); 

                    axios.post(this.$store.state.config.apiserver + 'clubs/panels/CorrectClub.php',posten)
                    .then(response => 
                    {           
                         
                         if (this.$store.state.config.debug==1) 
                         {
                         console.log(response.data);
                         }                                                         
                                                                         
                         this.setactiveproperty('panel','CorrectClubThankyou');

                    })
                    .catch(error => 
                    {
                         this.errorMessage = error.message;
                         console.error("There was an error!", error);
                    });



               }
            


   


          },     
          closepanel()
          {
               this.$store.dispatch('taskShowPanel',null);
 
          }
          

     },

    computed: 
    {


          taskShowPanel() 
          {              
               if (this.$store.state.task.showPanel=='correctclub') {
                    return true
               } else {
                    return false
               }               
          },

          translate() 
          {        
               return this.$store.state.translate;        
          },
          clubitems() {

               var club = this.$store.state.current.club;

               var skips = ['id','state','note','continent_id',"region_id","language_id","location","country_id","country_nl","country_de","country_fr","country_es","country_pt","country_it","country_slug","region_slug","continent_nl","continent_de","continent_fr","continent_es","continent_pt","continent_it","continent_slug","bookmarked","noclub","veld","zaal","slug"];

               var items = [];

               Object.keys(club).forEach(key => {

                    if (!skips.includes(key)) {

                         const item = new Object();
                         item.key = key;
                         item.value = club[key];
                         items.push(item)

                    }
                    
               });      


               return items;


          },
          club(){

               return this.$store.state.current.club;


          }



    },

    watch: 
    {

    }


  
}
</script>

 
<style scoped>

</style>
