import axios from "axios";

import store from "../store";

export default {
	fetchgames({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch games");
		}

		var post = new Object();

		post.task = "getgames";

		// console.log(post);

		axios.post(store.state.config.apiserver + "./games/GamesView.php", post)
			.then((response) => {
				// console.log(response.data);
				const payload = new Object();
				payload.property = "games";
				payload.value = response.data;

				commit("setgamesproperty", payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},

	fetchkaarten({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch kaarten");
		}

		var post = new Object();
		post.task = "getkaarten";

		axios.post(store.state.config.apiserver + "./games/views/PlaysPlay.php", post)
			.then((response) => {
				// 				console.log("---------------");
				//
				// 				console.log(response.data);

				const payload = new Object();
				payload.property = "kaarten";
				payload.value = response.data;

				commit("setstaticproperty", payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},
	fetchovertredingen({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch overtredingen");
		}

		var post = new Object();
		post.task = "getovertredingen";

		axios.post(store.state.config.apiserver + "./games/views/PlaysPlay.php", post)
			.then((response) => {
				const payload = new Object();
				payload.property = "overtredingen";
				payload.value = response.data;

				commit("setstaticproperty", payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},

	fetchstraffen({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch straffen");
		}

		var post = new Object();
		post.task = "getstraffen";

		axios.post(store.state.config.apiserver + "./games/views/PlaysPlay.php", post)
			.then((response) => {
				const payload = new Object();
				payload.property = "straffen";
				payload.value = response.data;

				commit("setstaticproperty", payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},
	fetchteams({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch teams");
		}

		var post = new Object();
		post.task = "getteams";

		axios.post(store.state.config.apiserver + "./games/views/PlaysPlay.php", post)
			.then((response) => {
				const payload = new Object();
				payload.property = "teams";
				payload.value = response.data;

				commit("setstaticproperty", payload);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},

	// 2024 SEPTEMBER

	async fetchcompetitions({commit}) {
		if (store.state.config.debug == 1) {
			console.log("fetch competitions");
		}

		var post = {
			task: "fetchcompetitions",
		};

		if (store.state.user) {
			post.hdaccesstoken = store.state.user.hdaccesstoken;
		}

		// console.log(post);

		try {
			const response = await axios.post(store.state.config.apiserver + "app-actions.php", post);

			var competitions = response.data;

			// console.log(response.data);

			// Verwerk het response
			commit("setcompetitions", competitions);
			commit("increaseloading");

			return response.data;
		} catch (error) {
			console.error("There was an error!", error);
			throw error;
		}
	},

	handleGamesRoute: async ({commit, state}, params) => {
		if (state.config.debug == 1) {
			console.log("handleGamesRoute");
		}

		// Competities ophalen als ze nog niet zijn geladen
		if (!state.competitions || state.competitions.length === 0) {
			var post = {
				task: "fetchcompetitions",
			};
			try {
				const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout bij ophalen competities")), 10000));
				const response = await Promise.race([axios.post(state.config.apiserver + "app-actions.php", post), timeoutPromise]);
				var competitions = response.data;
				commit("setcompetitions", competitions);
			} catch (error) {
				console.error("Fout bij het ophalen van competities:", error);
				throw error;
			}
		}

		// Competitie ophalen aan de hand van de slug
		const competitionSlug = params.competition;
		if (competitionSlug) {
			const competition = state.competitions.find((comp) => comp.slug === competitionSlug);
			if (competition) {
				try {
					commit("setCurrentCompetition", competition);
				} catch (error) {
					console.error("Fout bij het zetten van de huidige competitie:", error);
					throw error;
				}
			} else {
				console.log("Geen competitie gevonden voor slug:", competitionSlug);
			}
		}

		const gameNumber = params.game ? params.game.substring(5) : null;

		if (gameNumber && state.current.competition) {
			const game = state.current.competition.games.find((game) => game.game === gameNumber);
			if (game) {
				try {
					var status = "";

					if (game.status == "play") {
						status = "play";

						// if (game.numberofvideosplayedtobeplayed == "0") {
						// 	status = "wait";
						// }
					}

					if (game.status == "chat") {
						status = "chat";
					}

					if (game.forcestatus == "play") {
						status = "play";
					}
					if (game.forcestatus == "chat") {
						status = "chat";
					}

					game.status = status;

					commit("setCurrentGame", game);
				} catch (error) {
					console.error("Fout bij het zetten van de huidige game:", error);
					throw error;
				}
			}
		}

		const videoNumber = params.video ? params.video.substring(6) : null;
		if (videoNumber && state.current.game) {
			const video = state.current.game[`video_${videoNumber}`];

			if (video) {
				try {
					video.nummer = videoNumber;
					commit("setCurrentVideo", video);
				} catch (error) {
					console.error("Fout bij het zetten van de huidige video:", error);
					throw error;
				}
			} else {
				console.log("Geen video gevonden voor videoId:", video);
			}
		}

		commit("setCurrentMode", params.mode);

		return "ok";
	},

	handleRankingsRoute: async ({state, dispatch}, params) => {
		if (state.config.debug == 1) {
			console.log("handleRanginksRoute");
		}

		if (params.old.scope != params.new.scope) {
			console.log("nieuw");
		}

		await dispatch("calculatepoints");

		await dispatch("fetchrankings");

		return "ok";
	},

	calculatepoints() {
		if (store.state.config.debug == 1) {
			console.log("calculatepoints");
		}

		var post = new Object();
		post.task = "calculatepoints";

		//console.log(post);

		axios.post(store.state.config.apiserver + "./games/views/Rankings.php", post)
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},
	fetchrankings({commit}) {
		this.rankings = null;

		if (store.state.config.debug == 1) {
			console.log("fetchrankings");
		}

		var post = new Object();
		post.task = "getrankingseason";
		post.competition = store.state.current.competition.slug;

		if (store.state.route.scope) {
			post.scope = store.state.route.scope;
		}

		if (store.state.current.club) {
			post.clubid = store.state.current.club.id;
		}

		if (store.state.current.region) {
			post.regionid = store.state.current.region.id;
		}

		if (store.state.current.country) {
			post.countryid = store.state.current.country.id;
		}

		if (store.state.current.continent) {
			post.continentid = store.state.current.continent.id;
		}

		if (!store.state.route.scope) {
			return null;
		}

		// console.log(post);

		axios.post(store.state.config.apiserver + "./games/views/Rankings.php", post)
			.then((response) => {
				// console.log(response.data);

				if (response.data.length > 0) {
					this.rankingspresent = true;

					var rankings = response.data.sort(function (b, a) {
						return a.points - b.points;
					});

					var teller = 0;

					var newrankings = [];

					rankings.forEach(function (ranking) {
						teller++;

						ranking.ranking = teller;

						newrankings.push(ranking);
					});

					this.rankings = newrankings;
				} else {
					this.rankingspresent = false;
				}

				commit("SET_RANKINGS", newrankings);
			})
			.catch((error) => {
				this.errorMessage = error.message;
				console.error("There was an error!", error);
			});
	},
};
