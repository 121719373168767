<template>

     <transition name="slideup">

              <div v-if="this.$store.state.active.panel==panelname" class="panel">

               <div v-if="this.$store.state.config.debug==1" class="debug">Panel | {{panelname}}</div>

     

               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.COMMENTS_OPMERKINGENKOP}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="closepanel()"  class="bi bi-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content">

                    <div v-if="commentids">   
                    
                         <div v-for="commentid in commentids" :key="commentid">
                              <Comment v-bind:commentid="commentid"/>
                         </div>               
                    </div>


               </div>



          </div>
     </transition>     
  
</template>

<script>

import axios from "axios";
import Comment from '@/components/interactions/panels/ShowCommentsComment.vue';


export default {

     name: 'Comments',

     data() {
          return{               
              commentids: null,  
              panelname: 'ShowComments'     
                               
          }
     },
 
     components: {
          Comment,
     },
     methods:{

          getcommentids()
          {



               if (this.$store.state.config.debug==1) 
               {
                    console.log('getcommentids');
               }    

         
                    var post = new Object();
                    post.task = 'getcommentids';
                    post.parentid = this.$store.state.active.item.id;
                    post.parenttype = this.$store.state.active.item.type;

                    // console.log(post);

                    axios.post( this.$store.state.config.apiserver + 'interactions/panels/ShowComments.php', post)
                    .then(response => {
                         
                         this.commentids = response.data;                                      
                         // console.log(response.data);
                    })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
               });

              



          },
          closepanel(){

               this.setactiveproperty('task','refresh');
               this.setactiveproperty('taskitemid',this.$store.state.active.item.id);
               this.setactiveproperty('taskitemtype',this.$store.state.active.item.type);

               this.setactiveproperty('panel','');


          }
          

     },

    computed: 
    {
      
          activetaskchanges(){

               return this.$store.state.active.task;  
          },
          activepanel(){

               return this.$store.state.active.panel;

          }
 

    },
    watch: {

          activepanel(){

               if (this.$store.state.active.panel=="ShowComments"){


                    this.getcommentids();

               

               }



          },
          activetaskchanges(){

               if (this.$store.state.active.task == 'getcomments') {

                    this.getcommentids();
                    this.setactiveproperty('task','');

               }


             
          }


    }


  
}
</script>
