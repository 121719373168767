<template>


     <div class="view_subview">


          <div class="row"  >   

          

               <div class="col col-12 verticalcenter mb15"> 
                    <img class="profilepicture" :src="profilepicture"/>  

                    <div class="klein tekstgrijs">  
                         <span class="dik tekstzwart">{{member.userFirstname}} {{member.userLastname}}</span>
                    </div>     

               </div>     
               
          </div>


     </div>

     
</template>


<script>


export default{
    
     data(){
          return {    
                              
          }
     },
     props: ['member'],
     computed:{          
   
  
          profilepicture () {                      
               return 'https://www.hockeydo.com/users/'  + this.member.userimagename + '.png';

          },

          
     },
     watch: {



     },


     methods: {




     




     }
}
</script>

