import appactions from './app/app-actions.js'
import gamesactions from './games/games-actions.js'    
import postsactions from './posts/posts-actions.js'  
import homeactions from './home/home-actions.js' 
import weactions from './we/we-actions.js' 




export default { 
     
          ...appactions, 
          ...gamesactions,
          ...postsactions,
          ...homeactions,
          ...weactions
     
     }