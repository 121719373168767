<template>

     <transition name="slideup">

          <div v-if="this.store.state.active.panel=='CorrectClubThankyou'" class="panel">

           

     

               <div class="row panel_header">
                    <div class="col col-6 middle">
                         <h1>{{translate.ADDCLUB_CLUBTOEVOEGEN}}</h1>
                    </div>    
                    <div class="col-6 middle-right">
                         <a v-on:click="closepanel()"  class="bi bi-chevron-x tekstblauw floatright"></a>
                    </div> 
               </div>   

               <div class="panel_content verticalcenter horizontalcenter dik">


               {{translate.CORRECTCLUBTHANKYOU_THANKYOU}} 

          
               </div>


          </div>

     </transition>     
  
</template>

<script>





export default {

 
     data() {
          return{


          }
     },

 

     methods:{

    
          closepanel()
          {
               this.$store.dispatch('taskShowPanel',null);  

          }
          

     },

    computed: 
    {


          taskShowPanel() 
          {              
               if (this.$store.state.task.showPanel=='correctclubthankyou') {
                    return true
               } else {
                    return false
               }               
          },
          translate() 
          {        
               return this.$store.state.translate;        
          },

     

    },



  
}
</script>

 
<style scoped>

</style>
